import axios from "axios";

export const getRallCallByElection = async (secret, electionId) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/api/RallCalls/GetRallCallByElection?idElection=${electionId}`,
      {
        headers: {
          Authorization: `Bearer ${secret}`,
          Accept: "text/plain",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};
export const getCurrentRoundRallCall = async (secret, roundID) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/api/RallCalls/GetRallCallByRound?idRound=${roundID}`,
      {
        headers: {
          Authorization: `Bearer ${secret}`,
          Accept: "text/plain",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

export const PostRallCall = async (secret, roundRallCallData) => {
  try {
    const newDataWithoutId = roundRallCallData.map(
      ({ id, email, startDate, endDate, ...rest }) => ({
        ...rest,
      })
    );
    const response = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/api/RallCalls`,

      newDataWithoutId, // Sending roundRallCallData in the request body

      {
        headers: {
          Authorization: `Bearer ${secret}`,
          Accept: "text/plain",
        },
      }
    );

    return response;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error; // Re-throw the error for handling in the calling function
  }
};

export const UpdateStatusRallCall = async (
  secret,
  IDRallCall,
  status,
  index
) => {
  const indexToIntroduce = index !== undefined ? `&index=${index}` : "";
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/api/RallCalls/UpdateStatusRallCall?IDRallCall=${IDRallCall}&status=${status}${indexToIntroduce}`,
      {
        headers: {
          Authorization: `Bearer ${secret}`,
          Accept: "text/plain",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};
export const UpdateStatusRallCallIdVoter = async (
  secret,
  IDVoter,
  status,
  idRound,
  index
) => {
  const indexToIntroduce = index !== undefined ? `&index=${index}` : "";
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/api/RallCalls/UpdateStatusRallCallIdVoter?IDVoter=${IDVoter}&status=${status}&IdRound=${idRound}${indexToIntroduce}`,
      {
        headers: {
          Authorization: `Bearer ${secret}`,
          Accept: "text/plain",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};
