import Navbar from "../../components/AdminBanner";
import { Breadcrumb } from "antd";
import "./FinalResults.css";
import {
  getAllElections,
  getCurrentElectionYear,
} from "../../services/Elections";
import { fetchToken } from "../../services/Token";
import { getCountryCode } from "../../services/GetCountryCode";
import React, { useEffect, useState } from "react";
import ReactCountryFlag from "react-country-flag";
import { useLocation, useNavigate } from "react-router-dom";
import { getElectionResultsByCandidate } from "../../services/ElectionResults";
import { getWinnerByElection } from "../../services/Candidate";
import DOMPurify from "dompurify";

export const FinalResults = () => {
  const logoImageUrl = "../../assets/Logo.png";
  const [currentYear, setCurrentYear] = useState(null);
  const [token, setToken] = useState(null);
  const [localData, setLocalData] = useState(null);
  const [delayedRender, setDelayedRender] = useState(false);
  const [resultsPerRound, setResultsPerRound] = useState();
  const [electionId, setElectionId] = useState(null);
  const [winner, setWinner] = useState();
  const [isPet, setIsPet] = useState(null);
  const [isSg, setIsSg] = useState(null);
  const [isChairman, setIsChairman] = useState(null);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setDelayedRender(true);
    }, 1500);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  useEffect(() => {
    setLoading(true);
    let idTokenClaims = JSON.parse(localStorage.getItem("idTokenClaims"));
    setLocalData(idTokenClaims);
  }, []);

  useEffect(() => {
    setLoading(true);
    if (localData !== null) {
      fetchToken(localData).then((result) => {
        setToken(result);
      });
      setIsPet(localData?.roles?.includes("PET"));
      setIsChairman(localData?.roles?.includes("Chairman"));
      setIsSg(localData?.roles?.includes("SG"));
    }
  }, [localData]);
  useEffect(() => {
    if (isPet == false && isSg == false && isChairman == false) {
      navigate("/AccessDenied");
    }
  }, [isPet, isSg, isChairman]);

  useEffect(() => {
    setLoading(true);
    if (token !== null) {
      getAllElections(token).then((result) => {
        // Filter elections with status Closed, Adjourned, or Started
        const filteredElections = result?.filter((election) =>
          ["Closed", "Adjourned", "Started"].includes(election.status)
        );
        if (filteredElections) {
          filteredElections.sort((a, b) => {
            return new Date(a.startDate) - new Date(b.startDate);
          });
        }
        // Sort filtered elections based on their order

        if (filteredElections?.length > 0) {
          // Set electionId to the id of the last element
          setElectionId(filteredElections[filteredElections.length - 1].id);
        } else {
          // If no matching elections found, set electionId to null or handle accordingly
          setElectionId(null);
        }
      });
    }
  }, [token]);

  const formatPercentage = (percentage) => {
    const formattedPercentage = parseFloat(percentage).toFixed(2);
    return formattedPercentage;
  };

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      if (token !== null && electionId !== null) {
        try {
          const currentYearResult = await getCurrentElectionYear(
            token,
            electionId
          );
          if (currentYearResult !== null) {
            setCurrentYear(currentYearResult);
          }
          const result = await getWinnerByElection(token, electionId);
          if (result !== null) {
            setWinner(result);
          }
        } catch (error) {
          console.error("Error occurred:", error);
        }
      }
    };
    fetchData();
    fetchData().then(() => {
      // After data is fetched, set loading to false
      setLoading(false);
    });
  }, [electionId]);

  useEffect(() => {
    setLoading(true);

    const fetchRoundsData = async () => {
      if (token !== null && electionId !== null && winner !== null) {
        try {
          const result = await getElectionResultsByCandidate(
            token,
            winner?.id,
            electionId
          );
          setResultsPerRound(result);
        } catch (error) {
          console.error("Error occurred:", error);
        }
      }
    };
    fetchRoundsData();
    fetchRoundsData().then(() => {
      // After data is fetched, set loading to false
      setLoading(false);
    });
  }, [winner]);

  return (
    <>
      <div
        className="container-fluid"
        style={{
          backgroundColor: "#DAE6B6",
          height: "100vh",
          overflow: "auto",
        }}
      >
        <div className="row">
          <div className="col" style={{ marginBottom: "1%" }}>
            <Navbar />
          </div>
        </div>

        <div className="row">
          {/**BREADCRUMB COMPONENT*/}
          <div
            className="d-flex align-items-center"
            style={{ paddingLeft: "1rem", marginBottom: "1%" }}
          >
            <Breadcrumb separator=">" style={{ color: "rgba(0, 0, 0, 0.45)" }}>
              <Breadcrumb.Item>
                <a style={{ color: "#029455", cursor: "default" }}>
                  Résultats finaux/ Final results
                </a>
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>

          <div className="finalContainer">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginLeft: "10%",
              }}
            >
              <img
                src={logoImageUrl}
                alt="Logo"
                style={{ marginRight: "10px" }}
              />
              <div>
                <p className="electionYear">{currentYear} BAD/ADB</p>
                <p className="electionPresident">
                  Président élu/ Elected President
                </p>
              </div>
            </div>

            {loading && (
              // Loading state
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  height: "80%",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div>Loading...</div>{" "}
                {/* You can replace this with a spinner */}
              </div>
            )}

            {!loading && winner && (
              // Display winner if available
              <div
                className="presidentConatiner"
                style={{ backgroundColor: "#DAE6B6" }}
              >
                <div
                  className="presidentDetails"
                  style={{ height: "50%", backgroundColor: "#DAE6B6" }}
                >
                  <div>
                    <p className="presidentName">
                      {winner?.firstName} {winner?.lastName}
                    </p>
                  </div>
                  <div className="candidateCountry" style={{ height: "10%" }}>
                    <span>
                      <ReactCountryFlag
                        countryCode={getCountryCode(winner?.country)}
                        svg
                        style={{
                          width: "2rem",
                          height: "1.89063rem",
                          marginRight: "0.5rem",
                          marginLeft: "0.5rem",
                        }}
                      />
                    </span>
                    <span className="presidentCountry">{winner?.country}</span>
                  </div>
                  <div
                    className="d-flex"
                    style={{ height: "50%", justifyContent: "center" }}
                  >
                    <img
                      src={DOMPurify.sanitize(
                        `${winner?.urlPhoto}?${process.env.REACT_APP_SIGNATURE}`
                      )}
                      style={{
                        objectFit: "cover",
                        width: "100%",
                        maxHeight: "550px",
                        maxWidth: "550px",
                      }}
                    />
                  </div>
                  <div className="stats" style={{ height: "20%" }}>
                    <div className="stats-item">
                      Total de tous les votes/ Total of all votes
                      <br />
                      <p className="statsNumber">
                        {resultsPerRound && resultsPerRound.length > 0
                          ? formatPercentage(
                              resultsPerRound[resultsPerRound.length - 1]
                                ?.resultGlobal
                            )
                          : "0.00"}{" "}
                        %
                      </p>
                    </div>
                    <div className="vertical-line"></div>
                    <div className="stats-item">
                      Votes régionaux/ Regional vote
                      <br />
                      <p className="statsNumber">
                        {resultsPerRound && resultsPerRound.length > 0
                          ? formatPercentage(
                              resultsPerRound[resultsPerRound.length - 1]
                                ?.resultRegional
                            )
                          : "0.00"}{" "}
                        %
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {!loading && !winner && (
              // Display "No Data Available" if there's no winner and loading is complete
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  height: "80%",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <svg
                    width="184"
                    height="152"
                    viewBox="0 0 184 152"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g fill="none" fill-rule="evenodd">
                      <g transform="translate(24 31.67)">
                        <ellipse
                          fill-opacity=".8"
                          fill="#F5F5F7"
                          cx="67.797"
                          cy="106.89"
                          rx="67.797"
                          ry="12.668"
                        ></ellipse>
                        <path
                          d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
                          fill="#AEB8C2"
                        ></path>
                        <path
                          d="M101.537 86.214L80.63 61.102c-1.001-1.207-2.507-1.867-4.048-1.867H31.724c-1.54 0-3.047.66-4.048 1.867L6.769 86.214v13.792h94.768V86.214z"
                          fill="url(#linearGradient-1)"
                          transform="translate(13.56)"
                        ></path>
                        <path
                          d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
                          fill="#F5F5F7"
                        ></path>
                        <path
                          d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
                          fill="#DCE0E6"
                        ></path>
                      </g>
                      <path
                        d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
                        fill="#DCE0E6"
                      ></path>
                      <g transform="translate(149.65 15.383)" fill="#FFF">
                        <ellipse
                          cx="20.654"
                          cy="3.167"
                          rx="2.849"
                          ry="2.815"
                        ></ellipse>
                        <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z"></path>
                      </g>
                    </g>
                  </svg>
                  <h5 style={{}}>No Data Available</h5>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
