import React, { useEffect, useState, useRef } from "react";
import "./Voting.css";
import CircleComponent from "./CircleComponent";
import { GreyClose, greenTick, TimerIcon } from "../../../assets/Icons";
import {
  endCurrentRound,
  getRoundsByElection,
  startCurrentRound,
  updateStageRound,
} from "../../../services/Rounds";
import createWebSocketConnectionVotes from "../../../services/WebSocketVotesService";
import { getVoteStats } from "../../../services/Stats";
import { getViewCandidates } from "../../../services/Candidate";
import { publishResults } from "../../../services/ElectionResults";
import { getBusinessLog, ValidateResults } from "../../../services/BusinessLog";
import { getVotersByRound } from "../../../services/Voters";
import { notification } from "antd";
import CheckConnection from "../../../services/Detector.js";
import { useConnection } from "../../../Context/ConnectionContext";
import { useMsal } from "@azure/msal-react";

const VotingComponent = ({
  setVotersPerRound,
  roundId,
  votersPerRound,
  token,
  roundData,
  state,
  electionId,
  setState,
  localData,
  hasAccess,
}) => {
  const [showPopupStart, setshowPopupStart] = useState(false);
  const [showEndButton, setShowEndButton] = useState(false);
  const [yesVoters, setYesVoters] = useState(null);
  const [stat, setStat] = useState(null);
  const [statusValidation, setStatusValidation] = useState(0);
  const [winnerDetails, setWinnerDetails] = useState(null);
  const [roundNumber, setRoundNumber] = useState(null);
  const [candidatesPerRound, setCandidatesPerRound] = useState(null);
  const [resultsPosted, setResultsPosted] = useState(false);
  const { isOnline } = useConnection();
  const prevIsOnline = useRef(isOnline);
  const [delayedRender, setDelayedRender] = useState(false);
  const { accounts } = useMsal();
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [rounndId, setRounndId] = useState();
  const [showPopupValidate, setShowPopupValidate] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [disableStartRallCallButton, setDisableStartRallCallButton] =
    useState(false);
  function getCurrentDate() {
    const currentDate = new Date();
    const isoString = currentDate.toISOString(); // Get ISO string in the format "YYYY-MM-DDTHH:mm:ss.sssZ"
    const formattedDate = isoString.split("T")[0] + "T00:00:00"; // Keep only the date part and set the time to "00:00:00"
    return formattedDate;
  }
  const handleButtonClickValidate = () => {
    setShowPopupValidate(true);
  };
  const handleButtonClickConfirmValidate = async () => {
    const sysdate = getCurrentDate();
    setShowPopupValidate(false);

    if (!resultsPosted) {
      setIsButtonDisabled(true);
      try {
        const response = await ValidateResults(
          token,
          rounndId,
          sysdate,
          localData.preferred_username
        );
        if (response.status === 200) {
          notification.success({
            description: `Round validated successfully!`,
          });
        } else {
          notification.error({
            description: "Error",
          });
        }
        setResultsPosted(true);
      } catch (error) {
        console.error("Error validating results:", error);
      }
    }
  };
  const isSg = accounts[0]?.idTokenClaims?.roles?.includes("SG");
  useEffect(() => {
    if (roundId !== null) {
      getVotersByRound(token, roundId).then((result) => {
        setVotersPerRound(result.data);
      });
    }
  }, [roundId, electionId]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (token !== null && electionId != null) {
          // Get all rounds in the election
          const roundsByElection = await getRoundsByElection(token, electionId);

          // If there's only one round in the election
          if (roundsByElection.length === 1) {
            const onlyRound = roundsByElection[0];
            // If the only round is closed, set the necessary states for displaying the data
            if (onlyRound.status === "Closed") {
              setRoundNumber(onlyRound.number);
              setRounndId(onlyRound.id);
              setStartTime(onlyRound.startTime);
              setEndTime(onlyRound.endTime);
            } else {
              setIsButtonDisabled(true);
              // Do not display anything if the only round is not closed
            }
          } else if (roundsByElection.length > 1) {
            // If there are multiple rounds, find the last closed round
            const lastClosedRound = roundsByElection
              .filter((round) => round.status === "Closed")
              .pop(); // Get the last closed round            );
            if (lastClosedRound) {
              // Display the last closed round
              setRoundNumber(lastClosedRound.number);
              setRounndId(lastClosedRound.id);
              setStartTime(lastClosedRound.startTime);
              setEndTime(lastClosedRound.endTime);
            } else {
              setIsButtonDisabled(true);
            }
          }
        }
      } catch (error) {
        console.error("Error fetching round data:", error);
      }
    };

    fetchData();
  }, [token, electionId]);

  useEffect(() => {
    if (isOnline && prevIsOnline.current != isOnline) {
      console.log("welcome back to afdb Evoting app .");
      const socket = createWebSocketConnectionVotes();
      socket.onopen = () => {
        console.log("WebSocket of voters connected");
        // Send the initial message to start receiving data
        const initialMessage =
          JSON.stringify({ protocol: "json", version: 1 }) +
          String.fromCharCode(0x1e);
        socket.send(initialMessage);
      };
      prevIsOnline.current = isOnline;
    } else if (prevIsOnline.current !== isOnline) {
      prevIsOnline.current = isOnline;
      console.log("you are offline please check your internet connexion ...");
    }
  }, [isOnline]);

  //////WebSocket connection for Voters who voted
  useEffect(() => {
    if (token !== null && delayedRender) {
      const socket = createWebSocketConnectionVotes();
      socket.onopen = () => {
        // Send the initial message to start receiving data
        const initialMessage =
          JSON.stringify({ protocol: "json", version: 1 }) +
          String.fromCharCode(0x1e);
        socket.send(initialMessage);
      };
      // Initialize the state as an empty array
      setYesVoters([]);

      socket.onmessage = async (event) => {
        const rawData = event.data;
        const messages = rawData
          .split(String.fromCharCode(0x1e))
          .filter(Boolean);
        if (messages.length > 1) {
          const parsedData = JSON.parse(messages[1]);
          if (parsedData.target === "SendVote") {
            setYesVoters((prevYesVoters) => [
              ...prevYesVoters,
              parsedData.arguments[0],
            ]);
            try {
              const result = await getVoteStats(token, electionId, roundId);
              setStat(result);
            } catch (error) {
              console.error("Error fetching vote statistics:", error);
            }
          } else if (parsedData.target === "SendValidatedCount") {
            setStatusValidation(parsedData.arguments[0]);
          }
        } else if (messages.length === 1) {
          const data = messages[0];
          const parsedData = JSON.parse(data);
          const typevalue = parsedData.type;

          if (typevalue === 1) {
            if (parsedData.target === "SendVote") {
              setYesVoters((prevYesVoters) => [
                ...prevYesVoters,
                parsedData.arguments[0],
              ]);
              try {
                const result = await getVoteStats(token, electionId, roundId);
                setStat(result);
              } catch (error) {
                console.error("Error fetching vote statistics:", error);
              }
            } else if (parsedData.target === "SendValidatedCount") {
              setStatusValidation(parsedData.arguments[0]);
            }
          }
        }
      };
      socket.onerror = (error) => {
        console.error("WebSocket error:", error);
      };
      return () => {
        socket.close();
      };
    }
  }, [token, delayedRender]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (token !== null && electionId !== null && roundId !== null) {
          const result = await getVoteStats(token, electionId, roundId);
          setStat(result);
        }
      } catch (error) {
        console.error("Error fetching vote statistics:", error);
      }
    };
    fetchData();
  }, [electionId, roundId]);

  useEffect(() => {
    const checkIfResultsPosted = async () => {
      try {
        if (token !== null && roundId !== null) {
          const results = await getBusinessLog(token, roundId);

          // Filter the results based on the "title" property
          const filteredResults = results.filter(
            (result) => result.title === "Validation"
          );
          // Check if there are any filtered results
          const resultsPosted = filteredResults.length > 0;
          if (resultsPosted) {
            setStatusValidation(filteredResults.length);
          }
          // Update the state accordingly
          setResultsPosted(resultsPosted);
        }
      } catch (error) {
        console.error("Error checking results:", error);
        // Handle error as needed
      }
    };

    // When the component mounts, check if results are already posted
    checkIfResultsPosted();
  }, [token, roundId]);

  const formatPercentage = (percentage) => {
    return parseFloat(percentage).toFixed(2);
  };

  const handleButtonClick = () => {
    setshowPopupStart(true);
  };

  const handleButtonClickConfirmStart = async () => {
    setDisableStartRallCallButton(true);
    if (!navigator.onLine) {
      notification.error({
        message: "No Internet Connection",
        description: "Please check your internet connection and try again.",
      });
      setDisableStartRallCallButton(false);
      return; // Exit function if no internet connection
    }
    try {
      // Call the startCurrentRound function with the necessary parameters
      const response = await startCurrentRound(token, roundId);
      if (response.status == 200) {
        setDisableStartRallCallButton(false);
        notification.success({
          description: `Round started successfully!`,
        });
        setshowPopupStart(false);
        setShowEndButton(true);
      } else {
        setDisableStartRallCallButton(false);
        notification.error({
          description: "Error",
        });
      }
      setState(3);
      await updateStageRound(token, roundId, "3");
    } catch (error) {
      // Handle errors if necessary
      console.error("Error starting the round:", error);
    }
  };

  useEffect(() => {
    if (statusValidation === 3) {
      getViewCandidates(token, roundId).then((result) => {
        // Check if there is a winner
        const winnerExists = result?.some((item) => item.status === "Winner");

        // Update the state directly for winnerDetails
        if (winnerExists) {
          const winnerDetails = result?.find(
            (item) => item.status === "Winner"
          );
          setWinnerDetails(winnerDetails);
        }
        setCandidatesPerRound(result);
      });
    }
  }, [statusValidation]);

  const handleButtonClickConfirmEnd = async () => {
    setDisableStartRallCallButton(true);
    if (!navigator.onLine) {
      notification.error({
        message: "No Internet Connection",
        description: "Please check your internet connection and try again.",
      });
      setDisableStartRallCallButton(false);
      return; // Exit function if no internet connection
    }
    setshowPopupStart(false);
    try {
      const response = await endCurrentRound(token, roundId);
      if (response.status == 200) {
        setDisableStartRallCallButton(false);
        notification.success({
          description: `Round ended successfully!`,
        });
        setState(4);
        await updateStageRound(token, roundId, "4");
      } else {
        setDisableStartRallCallButton(false);
        notification.error({
          description: "Error",
        });
      }
    } catch (error) {
      console.error("Error ending the round:", error);
    }
  };

  const handlePublishRoundResult = async () => {
    if (!navigator.onLine) {
      notification.error({
        message: "No Internet Connection",
        description: "Please check your internet connection and try again.",
      });
      return; // Exit function if no internet connection
    }
    setLoadingButton(true);
    try {
      // console.log(candidatesPerRound);
      const resultsArray = candidatesPerRound.map((candidate) => ({
        roundNum: roundData.number,
        electionID: electionId,
        candidateID: candidate.id,
        resultRegional: candidate.total_RVP,
        resultGlobal: candidate.total_VP,
        status: candidate.status,
      }));
      const responsepublishresults = await publishResults(resultsArray, token);
      if (responsepublishresults.status == 200) {
        setState(5);
        const response = await updateStageRound(token, roundId, "5");
        if (response.status == 204) {
          notification.success({
            description: `Results published successfully!`,
          });
        } else {
          notification.error({
            description: "Error",
          });
        }
      } else if (responsepublishresults.response.status !== 200) {
        notification.error({
          description:
            "Results were not published correctly. Please Try Again ! ",
          duration: 5,
        });
      }
    } catch (error) {
      console.error("Error publishing round result:", error);
    } finally {
      setLoadingButton(false);
    }
  };

  const handlePopupClose = () => {
    setshowPopupStart(false);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setDelayedRender(true);
    }, 300);

    return () => {
      clearTimeout(timer);
    };
  }, []);
  return (
    <div>
      <CheckConnection />
      <div
        className="row"
        style={{ backgroundColor: "#DAE6B6", height: "80vh" }}
      >
        {/* <h1>This is for no SG</h1> */}
        {/* Table Component */}
        <div
          className="col-8 col-xl-9"
          style={{
            height: "100%",
            padding: "0.5rem",
            border: "3px solid white",
            backgroundColor: "#DAE6B6",
            borderRadius: "1.25rem",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "10px",
              marginBottom: "20px",
              padding: "0.5rem",
            }}
          >
            <h4 className="VotingTitle">Vote/Voting</h4>
            {localData && (
              <button
                className={
                  localData?.roles[0] === "Chairman" ||
                  state === 4 ||
                  !hasAccess
                    ? "disabledButton"
                    : "StartRoundButton"
                }
                onClick={handleButtonClick}
                disabled={
                  localData?.roles[0] === "Chairman" || state == 4 || !hasAccess
                }
              >
                {state == 3 || state == 4
                  ? "Terminer le tour/End round"
                  : "Démarrer le tour/Start round"}
              </button>
            )}
          </div>
          <div className="w-100" style={{ height: "78%", overflow: "auto" }}>
            <table className="w-100">
              <thead>
                <tr className="HeaderTitles">
                  <th style={{ paddingLeft: "5px" }}>
                    Pays
                    <br />
                    Country
                  </th>
                  <th>
                    Régional/Non Régional
                    <br />
                    Regional/Non Regional
                  </th>
                  <th>
                    A voté?
                    <br />
                    Voted?
                  </th>
                </tr>
              </thead>
              {votersPerRound ? (
                <tbody style={{ padding: "1rem" }} className="BodyText">
                  {votersPerRound?.map((item, index) => {
                    const hasVoted =
                      yesVoters?.includes(item.voters.email) ||
                      (item.voters.votes.length > 0 &&
                        item.voters.votes.some(
                          (vote) => vote.roundID === roundId
                        ));
                    return (
                      <tr
                        key={index}
                        className={
                          item.voters.country.regional
                            ? "yellow-row"
                            : "green-row"
                        }
                        style={{ borderBottom: "1px solid #E3E8C4" }}
                      >
                        <td style={{ width: "30%", padding: "1rem" }}>
                          {item.voters.country.title}
                        </td>
                        {item.voters.country.regional ? (
                          <td>Regional/Regional</td>
                        ) : (
                          <td>Non Regional/Non Regional</td>
                        )}
                        <td>
                          {state == 3 || state == 4
                            ? hasVoted
                              ? "Oui/Yes"
                              : "Non/No"
                            : "Non/No"}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              ) : (
                <div className="container-fluid" style={{ height: "80vh" }}>
                  <div
                    className="spinner-border text-success"
                    role="status"
                    style={{
                      marginLeft: "100%",
                      marginTop: "30%",
                      width: "70px",
                      height: "70px",
                    }}
                  ></div>
                </div>
              )}
            </table>
          </div>
        </div>

        {/* STATS COMPONENT */}
        <div
          className="col-4 col-xl-3 d-flex flex-column justify-content-between "
          style={{ height: "90%" }}
        >
          {/* COMPONENT 1 */}
          <div
            style={{
              marginBottom: "4%",
              borderRadius: "1.25rem",
              paddingRight: "0.75rem",
              paddingLeft: "0.75rem",
              backgroundColor: "#DAE6B6",
              border: "3px solid white",
            }}
          >
            <p className="StatTitle" style={{ lineHeight: "1.2" }}>
              Bulletin Null/ Void Ballot
            </p>

            <div
              className="new-space"
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <CircleComponent
                number={
                  state == 3 || state == 4
                    ? stat
                      ? formatPercentage(stat.voidBallots) + "%"
                      : "0.00%"
                    : "0.00%"
                }
              />
            </div>
          </div>

          {/* COMPONENT 2 */}
          <div
            style={{
              marginBottom: "4%",
              borderRadius: "1.25rem",
              paddingRight: "0.75rem",
              paddingLeft: "0.75rem",
              backgroundColor: "#DAE6B6",
              border: "3px solid white",
            }}
          >
            <div>
              <p className="StatTitle">
                Pourcentage du total des votes exprimés / Percentage of Total
                votes cast
              </p>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <CircleComponent
                pourcentage={
                  state == 3 || state == 4
                    ? stat
                      ? formatPercentage(stat.pourcentageVotingPower) + "%"
                      : "0.00%"
                    : "0.00%"
                }
              />
            </div>
          </div>

          {/* COMPONENT 3 */}
          <div
            style={{
              borderRadius: "1.25rem",
              paddingRight: "0.75rem",
              paddingLeft: "0.75rem",
              backgroundColor: "#DAE6B6",
              border: "3px solid white",
            }}
          >
            <div>
              <p className="StatTitle">
                Pourcentage des votes régionaux / Percentage of Regional votes
              </p>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <CircleComponent
                pourcentage={
                  state == 3 || state == 4
                    ? stat
                      ? formatPercentage(stat.pourcentageRegional) + "%"
                      : "0.00%"
                    : "0.00%"
                }
              />
            </div>
          </div>
          {/* COMPONENT 4 */}
          <div
            style={{
              borderRadius: "1.25rem",
              paddingRight: "0.75rem",
              paddingLeft: "0.75rem",
              backgroundColor: "#DAE6B6",
              border: "3px solid white",
              marginTop: "3%",
            }}
          >
            <div>
              <p className="StatTitle">
                Pourcentage du total des abstentions / Percentage of Total
                abstention{" "}
              </p>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <CircleComponent
                pourcentage={
                  state == 3 || state == 4
                    ? stat
                      ? formatPercentage(stat.pourcentageAbstention) + "%"
                      : "0.00%"
                    : "0.00%"
                }
                style={{}}
              />
            </div>
          </div>
        </div>
      </div>

      {showPopupStart && (
        <div className="PopupConfirmStart">
          <div className="contentConfirmStart">
            <div>
              <div className="Header">
                <div className="TickIcon">{greenTick}</div>
                <button
                  style={{ border: "none", backgroundColor: "#FFF" }}
                  onClick={handlePopupClose}
                >
                  {GreyClose}
                </button>
              </div>
              <div className="Text">
                <div>
                  <div>
                    <h6>Confirmation/Confirmation</h6>
                  </div>
                  <div>
                    {state == 3 ? (
                      <p className="informingText">
                        Voulez-vous terminer le tour
                        <br />
                        Would you like to end the round
                      </p>
                    ) : (
                      <p className="informingText">
                        Voulez-vous démarrer le tour
                        <br />
                        Would you start the round
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <div className="abstainButtons">
                <button
                  className="buttonChange"
                  style={{ border: "1px solid #029455", color: "#029455" }}
                  onClick={handlePopupClose}
                >
                  Retourner/Go back
                </button>
                <button
                  className="buttonConfirm"
                  style={{ background: "#029455", color: "#FFF" }}
                  disabled={disableStartRallCallButton}
                  onClick={
                    state == 3
                      ? handleButtonClickConfirmEnd
                      : handleButtonClickConfirmStart
                  }
                >
                  Confirmer/Confirm
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {state &&
        state == 4 &&
        (statusValidation < 3 ? (
          <div className="PopupValidation">
            <div className="contentConfirmStart">
              {/**header */}
              <div className="Header d-flex justify-content-center">
                <div>{TimerIcon}</div>
              </div>

              <div className="text-center">
                <h6 className="textStylePopUp">
                  Les résultats du tour sont sous l'examen des scrutateurs
                </h6>
                <h6 className="textStylePopUp">
                  Results of this round are under review by Returning Officers
                </h6>
              </div>

              <div
                className="d-flex justify-content-center "
                style={{
                  backgroundColor: "white",
                  width: "100%",
                  marginTop: "4%",
                }}
              >
                <div
                  className="d-flex justify-content-center"
                  style={{
                    backgroundColor: "rgba(89, 104, 100, 0.08)",
                    marginBottom: "2%",
                    width: "60%",
                    border: "1px solid #ccc",
                  }}
                >
                  <p>
                    Statut de Validation/Validation Status : {statusValidation}
                    /3{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        ) : (
          statusValidation == 3 && (
            <div className="PopupValidationRedirection">
              <div className="contentConfirmStartvalidationRedirection">
                <div>
                  <div className="Header">
                    <div className="TickIcon">{greenTick}</div>
                  </div>
                  <div className="Text">
                    <div>
                      <div>
                        <h6
                          style={{
                            color: "#029455",
                            fontFamily: "DM Sans",
                            fontSize: "1rem",
                            fontStyle: "normal",
                            fontWeight: "700",
                            lineHeight: "normal",
                          }}
                        >
                          Résultats validés/ Résults are validated
                        </h6>
                        <h5
                          style={{
                            color: "#2A2F48",
                            fontFamily: "DM Sans",
                            fontSize: "1.0625rem",
                            fontStyle: "normal",
                            fontWeight: "700",
                            lineHeight: "normal",
                          }}
                        >
                          Publication des résultats/ Publication of results
                        </h5>
                        <p
                          style={{
                            color: "#6B6F89",
                            fontFamily: "DM Sans",
                            fontSize: "0.875rem",
                            fontStyle: "normal",
                            fontWeight: "500",
                            lineHeight: "168.7%",
                          }}
                        >
                          Résultats validés par les scrutateurs , vous pouvez
                          publier les résultats du tour.
                        </p>
                        <p>
                          Results are validated by the Returning Officers , you
                          can publish round results
                        </p>
                      </div>
                      <div></div>
                    </div>
                  </div>
                  {localData && (
                    <div className="abstainButtons">
                      <button
                        className="buttonConfirm"
                        style={{
                          background:
                            localData?.roles[0] === "Chairman" ||
                            !hasAccess ||
                            loadingButton
                              ? "rgb(215, 215, 215)"
                              : "#029455",
                          color:
                            localData?.roles[0] === "Chairman" ||
                            !hasAccess ||
                            loadingButton
                              ? "black"
                              : "#FFF",
                        }}
                        onClick={handlePublishRoundResult}
                        disabled={
                          localData?.roles[0] === "Chairman" ||
                          !hasAccess ||
                          loadingButton
                        }
                      >
                        Publier les résultats du tour/Publish round results
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )
        ))}
    </div>
  );
};

export default VotingComponent;
