import { PageLayout } from "./components/PageLayout";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import { ElectionContent } from "./Pages/ElectionContent.jsx";
import MainContent from "./Pages/MainContent";
import { ElectionProcess } from "./Pages/AdminSubComponents/ElectionProcess";
import { FinalResults } from "./Pages/AdminSubComponents/FinalResults";
import { ResultsRound } from "./Pages/AdminSubComponents/ResultsRound";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "./authConfig";
import { callMsGraph } from "./graph.js";
import { React, useState, useEffect } from "react";
import AdminContent from "./Pages/ITAdmin/AdminContent.jsx";
import EditElection from "./Pages/ITAdmin/EditElection.jsx";
import { ROValidation } from "./Pages/ReturningOfficerComponents/ROValidation.jsx";
import { AdjournScreen } from "./Pages/AdminSubComponents/AdjournScreen.jsx";
import { AccessDenied } from "./Pages/AccessDenied.jsx";
import { CandidateRound } from "./Pages/AdminSubComponents/CandidateRound.jsx";
import ElectionListSG from "./Pages/ElectionListSG.jsx";
import { ConnectionProvider } from "./Context/ConnectionContext.js";
import { ROValidationForSG } from "./Pages/ReturningOfficerComponents/ROValidationForSG.jsx";
import { fetchToken } from "./services/Token.js";

export default function App() {
  const { instance, accounts } = useMsal();
  const [idTokenClaims, setIdTokenClaims] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isReturningOfficer, setIsReturningOfficer] = useState(false);
  const [isPet, setIsPet] = useState(false);
  const [isSg, setIsSg] = useState(false);
  const [isGovernor, setIsGovernor] = useState(false);
  const [isChairman, setIsChairman] = useState(false);
  const [hasNoRoles, setHasNoRoles] = useState(false);
  const [token, setToken] = useState(null);
  const handleLogout = (logoutType) => {
    localStorage.clear();
    if (logoutType === "redirect") {
      instance.logoutRedirect({
        postLogoutRedirectUri: "/",
      });
    }
  };
  function isTokenExpired(token) {
    // Fix for Base64 URL encoding issues
    function decodeBase64Url(base64Url) {
      // Replace URL-safe characters to Base64 standard
      let base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      // Add padding if necessary
      switch (base64.length % 4) {
        case 2:
          base64 += "==";
          break;
        case 3:
          base64 += "=";
          break;
        default:
          break;
      }
      return atob(base64);
    }

    try {
      // Split the token and decode the payload
      const payloadBase64Url = token.split(".")[1]; // Get the payload part
      const payloadDecoded = JSON.parse(decodeBase64Url(payloadBase64Url)); // Decode the payload

      const exp = payloadDecoded.exp; // Get the expiration time (Unix timestamp)
      const currentTime = Math.floor(Date.now() / 1000); // Get the current time in seconds

      // Compare current time with expiration time
      if (currentTime > exp) {
        // console.log("Token has expired");
        handleLogout("redirect");
        return true; // Token has expired
      } else {
        // console.log("Token is still valid");
        return false; // Token is still valid
      }
    } catch (error) {
      console.error("Invalid token", error);
      return true; // Treat invalid token as expired
    }
  }

  function RequestProfileData() {
    let accessToken = null;

    instance
      .acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
      })
      .then((response) => {
        // Store the acquired access token in a variable
        accessToken = response.accessToken;

        // Call the MS Graph API with the acquired access token
        return callMsGraph(accessToken);
      })
      .then((response) => {
        // Set idTokenClaims in state and localStorage
        setIdTokenClaims(accounts[0].idTokenClaims);
        localStorage.setItem(
          "idTokenClaims",
          JSON.stringify(accounts[0].idTokenClaims)
        );
      })
      .then((response) => {
        const claims = JSON.parse(localStorage.getItem("idTokenClaims"));
        console.log(claims);
        const responseToken = fetchToken(claims).then((result) => {
          // Update the state with the resolved value
          setToken(result);
          console.log("this is the result", result);
          isTokenExpired(result);
          fetch(
            `${process.env.REACT_APP_API_BASE_URL}/api/CookieAuth/set-cookie`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${result}`,
              },
              credentials: "include",
            }
          )
            .then((response) => {
              if (!response.ok) {
                throw new Error("Failed to set authentication cookie.");
              }
              return response.json();
            })
            .then((data) => {
              console.log(
                "Authentication cookie set successfully:",
                data.message
              );
            })
            .catch((error) => {
              console.error("Error during profile data request:", error);
            });
        });
        console.log("this is the token", responseToken);
      })
      .catch((error) => {
        if (error.errorCode === "monitor_window_timeout") {
          // Appel de la fonction logout pour d�connecter l'utilisateur
          handleLogout("redirect");
        } else {
          console.error("Token acquisition failed for another reason:", error);
        }
      });
  }

  useEffect(() => {
    if (accounts.length > 0 && !idTokenClaims) {
      RequestProfileData();
    }
  }, [accounts, idTokenClaims]);

  useEffect(() => {
    if (idTokenClaims !== null) {
      setIsAdmin(idTokenClaims?.roles?.includes("Admin"));
      setIsReturningOfficer(idTokenClaims?.roles?.includes("ReturningOfficer"));
      setIsPet(idTokenClaims?.roles?.includes("PET"));
      setIsGovernor(idTokenClaims?.roles?.includes("Governors"));
      setIsSg(idTokenClaims?.roles?.includes("SG"));
      setIsChairman(idTokenClaims?.roles?.includes("Chairman"));
      const roles = idTokenClaims?.roles;
      setHasNoRoles(!roles || roles.length === 0);
    }
  }, [idTokenClaims]);

  useEffect(() => {
    const storedIdTokenClaims = localStorage.getItem("idTokenClaims");
    if (storedIdTokenClaims) {
      setIdTokenClaims(JSON.parse(storedIdTokenClaims));
    }
  }, []);

  const getComponentForRole = () => {
    if (isPet || isSg || isChairman) {
      return <ElectionContent />;
    } else if (isReturningOfficer) {
      return <ROValidation />;
    } else if (isGovernor) {
      return <MainContent />;
    } else if (isAdmin) {
      return <AdminContent />;
    } else if (hasNoRoles) {
      return <AccessDenied />;
    }
    return null;
  };
  useEffect(() => {
    // Only run the interval when the token is not null
    if (token) {
      const intervalId = setInterval(() => {
        isTokenExpired(token);
      }, 5000); // Run every 5 seconds

      // Cleanup the interval when the component unmounts or token changes
      return () => clearInterval(intervalId);
    }
  }, [token]);
  return (
    <ConnectionProvider>
      <BrowserRouter>
        <PageLayout>
          <Routes>
            <Route path="/" element={getComponentForRole()} />
            <Route
              path="/ElectionContent/Election_process"
              element={<ElectionProcess />}
            />
            <Route
              path="/ElectionContent/resultsRound"
              element={<ResultsRound />}
            />
            <Route
              path="/ElectionContent/finalResults"
              element={<FinalResults />}
            />
            <Route
              path="/ElectionContent/CandidateView"
              element={<CandidateRound />}
            />
            <Route
              path="/ElectionContent/Adjournment"
              element={<AdjournScreen />}
            />
            <Route
              path="/editElection/:electionId"
              element={<EditElection />}
            />
            <Route path="/AccessDenied" element={<AccessDenied />} />
            <Route path="/SgElectionList" element={<ElectionListSG />} />
            <Route path="/SgReturningOfficer" element={<ROValidationForSG />} />
          </Routes>
        </PageLayout>
      </BrowserRouter>
    </ConnectionProvider>
  );
}
