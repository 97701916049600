import { useEffect, useState } from "react";
import {
  DeleteElectionsById,
  ExportElection,
  ExportElectionSG,
  PostElection,
  UpdateElectionsById,
  getAllElections,
} from "../services/Elections";
import { fetchToken } from "../services/Token";
import {
  ViewIcon,
  EditIcon,
  TrashIcon,
  CheckIcon,
  EditIconDisabled,
  TrashIconDisabled,
  ViewIconDisabled,
  CheckIconDisabled,
  GreyClose,
  greenTick,
  exportgray,
  exportgreen,
} from "../assets/Icons";
import { useNavigate } from "react-router-dom";
import { sha256 } from "js-sha256";
import { notification } from "antd";
import { ExportOutlined } from "@ant-design/icons";
import Navbar from "../components/AdminBanner";
const ElectionListSG = () => {
  const [token, setToken] = useState(null);
  const [localData, setLocalData] = useState(null);
  const [elections, setElections] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [itemToCheck, setItemToCheck] = useState(null);
  const navigate = useNavigate();
  const name = JSON.parse(localStorage.getItem("idTokenClaims")).name;
  const secretKey = process.env.REACT_APP_SECRET_KEY;
  const [isSg, setIsSg] = useState(null);

  const hashValue = (value) => {
    return sha256.hmac(secretKey, value.toString());
  };
  const openShowPopUp = (item) => {
    setShowPopup(true);
    setItemToCheck(item);
    // Logic to determine showEndButton state based on your conditions
    // You can set setShowEndButton(true) or setShowEndButton(false) accordingly
  };
  const handlePopupClose = () => {
    setShowPopup(false);
    setItemToCheck(null);
  };
  const handleCreateElection = async () => {
    try {
      const response = await PostElection(token, name);
      setElections((prevElections) => [...prevElections, response.data]);
      const hashedValue = hashValue("true");
      localStorage.setItem("editMode", hashedValue);
      navigate(`/editElection/${response?.data.id}?editMode=true`);
    } catch (error) {
      console.log(
        "An Error happened while creating the election , Please Try Again ...."
      );
    }
  };
  const handleEditElection = (electionId) => {
    const hashedValue = hashValue("true");
    localStorage.setItem("editMode", hashedValue);
    navigate(`/editElection/${electionId}?editMode=true`);
  };

  const handleViewElection = (electionId) => {
    const hashedValue = hashValue("false");
    localStorage.setItem("editMode", hashedValue);
    navigate(`/editElection/${electionId}?editMode=false`);
  };

  const handleDeleteElection = async (token, electionId) => {
    try {
      await DeleteElectionsById(token, electionId);
      setElections((prevElections) =>
        prevElections.filter((election) => election.id !== electionId)
      );
    } catch (error) {
      console.log("Failed to delete the election, Please Try Again ");
    }
  };
  const handleExport = async (token, id) => {
    try {
      notification.info({
        description: "Exporting...",
        duration: null, // null means the notification won't automatically close
      });

      const response = await ExportElectionSG(token, id);
      if (response.status === 200) {
        notification.destroy();
        notification.success({
          description: "Successfully exported.",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleCheckElection = async (token, item) => {
    try {
      const statusCode = await UpdateElectionsById(token, item.id, {
        ...item,
        status: "Created",
      });
      setShowPopup(false);
      if (statusCode.status === 204) {
        // Operation successful, update the state
        setElections((prevElections) =>
          prevElections.map((election) =>
            election.id === item.id
              ? { ...election, status: "Created" }
              : election
          )
        );

        // Close the popup or perform any other necessary actions
        setShowPopup(false);
      } else {
        //console.log("Unexpected status code:", statusCode);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    let idTokenClaims = JSON.parse(localStorage.getItem("idTokenClaims"));
    setLocalData(idTokenClaims);
  }, []);

  useEffect(() => {
    if (localData !== null) {
      fetchToken(localData).then((result) => {
        setToken(result);
      });
      if (!localData?.roles?.includes("SG")) {
        navigate("/AccessDenied");
      }
      setIsSg(localData?.roles?.includes("SG"));
    }
  }, [localData]);
  useEffect(() => {
    if (isSg == false) {
      navigate("/AccessDenied");
    }
  }, [isSg]);
  useEffect(() => {
    if (token !== null) {
      getAllElections(token).then((result) => {
        const filteredElections = result?.filter(
          (election) =>
            election.status === "Closed" || election.status === "Adjourned"
        );
        setElections(filteredElections);
      });
    }
  }, [token]);
  return (
    <div
      style={{
        backgroundColor: "",
        width: "98%",
        margin: "0 auto",
        height: "90vh",
      }}
    >
      <div className="row" style={{ marginBottom: "5%" }}>
        <div className="col">
          <Navbar />
        </div>
      </div>

      {showPopup && (
        <div className="PopupConfirmStart">
          <div className="contentConfirmStart">
            {/* Display based on showEndButton state */}
            <div className="Header">
              <div className="TickIcon">{greenTick}</div>
              <button
                style={{ border: "none", backgroundColor: "#FFF" }}
                onClick={handlePopupClose}
              >
                {GreyClose}
              </button>
            </div>
            <p className="informingText">
              Are you sure you want to validate the election?
              <br />
              Please note that the election will become non-editable after
              validation
            </p>
            {/* Buttons for confirmation and cancellation */}
            <div className="abstainButtons">
              <button
                className="buttonChange"
                style={{ border: "1px solid #029455", color: "#029455" }}
                onClick={handlePopupClose}
              >
                Retourner/Go back
              </button>

              <button
                className="buttonConfirm"
                style={{ background: "#029455", color: "#FFF" }}
                onClick={() => handleCheckElection(token, itemToCheck)}
              >
                Confirmer/Confirm
              </button>
            </div>
          </div>
        </div>
      )}
      <table className="w-100 tab-election" style={{ marginLeft: "0.5rem" }}>
        <thead className="sticky ">
          <tr className="HeaderTitlesPublication">
            <th
              style={{
                width: "25%",
                padding: "1rem 0.8rem",
                textAlign: "left",
              }}
            >
              Election name
            </th>

            <th
              style={{
                width: "25%",
                padding: "1rem 0.8rem",
                textAlign: "center",
              }}
            >
              Start Date
            </th>
            <th
              style={{
                width: "25%",
                padding: "1rem 0.8rem",
                textAlign: "center",
              }}
            >
              End Date
            </th>
            <th
              style={{
                width: "20%",
                padding: "1rem 0.8rem",
                textAlign: "center",
              }}
            >
              Status
            </th>

            <th style={{ width: "5%", textAlign: "center" }}>Export</th>
          </tr>
        </thead>
        {elections && (
          <tbody style={{ padding: "1rem" }} className="BodyTextPublication">
            {elections.map((item, index) => {
              const parsedCreatedOn = item.createdOn?.split("T")[0];
              const parsedStartDate = item.startDate?.split("T")[0];
              const parsedEndDate = item.endDate?.split("T")[0];
              return (
                <tr
                  style={{
                    backgroundColor:
                      index % 2 === 0 ? "#F6F6F6" : "rgba(255, 255, 255, 0.46)",
                  }}
                  key={index}
                >
                  <td
                    style={{ padding: "1rem", textAlign: "left" }}
                    className="BodyTablePublication"
                  >
                    {item.title}
                  </td>

                  <td style={{ textAlign: "center" }}>{parsedStartDate}</td>
                  <td style={{ textAlign: "center" }}>{parsedEndDate}</td>
                  <td
                    style={
                      item.status === "Closed"
                        ? { color: "#2D3748", textAlign: "center" }
                        : item.status === "Draft"
                        ? { color: "#DF8F16", textAlign: "center" }
                        : item.status === "Created"
                        ? { color: "#3A1EB5", textAlign: "center" }
                        : item.status === "Adjourned"
                        ? { color: "#E81414", textAlign: "center" }
                        : item.status === "Started"
                        ? { color: "#029455", textAlign: "center" }
                        : { color: "black", textAlign: "center" }
                    }
                  >
                    {item.status}
                  </td>

                  <td style={{ textAlign: "center" }}>
                    <button
                      style={{ border: "none", background: "transparent" }}
                      onClick={() => handleExport(token, item.id)}
                      disabled={
                        item.status !== "Closed" && item.status !== "Adjourned"
                      }
                    >
                      {item.status === "Closed" || item.status === "Adjourned"
                        ? exportgreen
                        : exportgray}
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        )}
      </table>
    </div>
  );
};
export default ElectionListSG;
