import axios from "axios";
import { fetchToken } from "./Token";

export const getStartedRound = async (secret, electionId) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/api/Rounds/getStartedRounds?electionID=${electionId}`,
      {
        headers: {
          Authorization: `Bearer ${secret}`,
          Accept: "text/plain",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

export const startCurrentRound = async (secret, roundId) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/api/Rounds/StartRound?id=${roundId}`,
      {
        headers: {
          Authorization: `Bearer ${secret}`,
          Accept: "text/plain",
        },
      }
    );
    return response;
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

export const endCurrentRound = async (secret, roundId) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/api/Rounds/CloseRound?id=${roundId}`,
      {
        headers: {
          Authorization: `Bearer ${secret}`,
          Accept: "text/plain",
        },
      }
    );
    return response;
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

export const getLastCreatedRound = async (secret, electionId) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/api/Rounds/GetRoundsByStatus?electionID=${electionId}&Status=Created`,
      {
        headers: {
          Authorization: `Bearer ${secret}`,
          Accept: "text/plain",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

export const getLastClosedRound = async (secret, electionId) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/api/Rounds/GetRoundsByStatus?electionID=${electionId}&Status=Closed`,
      {
        headers: {
          Authorization: `Bearer ${secret}`,
          Accept: "text/plain",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

export const getRoundsByStatus = async (secret, electionID) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/api/Rounds/GetRoundsByStatus?electionID=${electionID}&Status=Created`,
      {
        headers: {
          Authorization: `Bearer ${secret}`,
          Accept: "text/plain",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error; // Throw the error to handle it further if needed
  }
};

export const updateStageRound = async (secret, roundId, stageProcess) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/api/Rounds/UpdateStageRound?id=${roundId}&Stage=${stageProcess}`,
      {
        headers: {
          Authorization: `Bearer ${secret}`,
          Accept: "application/json",
        },
      }
    );
    return response;
  } catch (error) {
    console.error("Error updating round:", error);
    throw error;
  }
};

export const createNewRound = async (secret, electionId) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/api/Rounds`,
      {
        electionID: electionId,
        status: "Created",
        stageProcess: "0",
      },
      {
        headers: {
          Authorization: `Bearer ${secret}`,
          Accept: "text/plain",
          "Content-Type": "application/json",
        },
      }
    );

    return response;
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

export const getLastRound = async (secret, electionId) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/api/Rounds/GetLastRoundsByElection?electionID=${electionId}`,
      {
        headers: {
          Authorization: `Bearer ${secret}`,
          Accept: "text/plain",
        },
      }
    );
    return response.data;
  } catch (error) {
    // console.error('Error fetching data:', error);
  }
};
export const getRoundsByElection = async (secret, electionId) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/api/Rounds/GetRoundsByElection?electionID=${electionId}`,
      {
        headers: {
          Authorization: `Bearer ${secret}`,
          Accept: "text/plain",
        },
      }
    );
    return response.data;
  } catch (error) {
    //  console.error("Error fetching rounds by election:", error);
  }
};
export const getRoundById = async (secret, roundId) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/api/Rounds/${roundId}`,
      {
        headers: {
          Authorization: `Bearer ${secret}`,
          Accept: "text/plain",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};
