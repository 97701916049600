import { useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import { useLocation, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { MenuOutlined } from "@ant-design/icons";
import { notification } from "antd";
import "./ElectionContent.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import { fetchToken } from "../services/Token";
import {
  getCurrentElectionID,
  getCurrentElectionYear,
  getElectionByStatus,
  startElection,
} from "../services/Elections";
import { greenTick } from "../assets/Icons";
import Navbar from "../components/AdminBanner";
import createWebSocketConnectionVotes from "../services/WebSocketVotesService";
export const ElectionContent = () => {
  const logoImageUrl = "../../assets/Logo.png";
  const navigate = useNavigate();
  const [token, setToken] = useState(null);
  const [localData, setLocalData] = useState(null);
  const [electionId, setElectionId] = useState(null);
  const [onGoingElection, setOngoingElection] = useState();
  const [createdElectionExists, setCreatedElectionExists] = useState();
  const [currentYear, setCurrentYear] = useState();
  const [showPopUp, setShowPopUp] = useState(false);
  const [isPet, setIsPet] = useState(false);
  const [isSg, setIsSg] = useState(false);
  const [isChairman, setIsChairman] = useState(false);
  const location = useLocation();
  const currentPath = location.pathname;
  useEffect(() => {
    let idTokenClaims = JSON.parse(localStorage.getItem("idTokenClaims"));
    setLocalData(idTokenClaims);
  }, []);

  useEffect(() => {
    if (localData !== null) {
      fetchToken(localData).then((result) => {
        setToken(result);
      });
      setIsPet(localData?.roles?.includes("PET"));
      setIsChairman(localData?.roles?.includes("Chairman"));
      setIsSg(localData?.roles?.includes("SG"));
    }
  }, [localData]);
  useEffect(() => {
    if (token !== null) {
      getCurrentElectionID(token).then((result) => {
        setElectionId(result?.id);
        // Set ongoingElection based on the result
        setOngoingElection(result ? true : false);
      });
    }
    const socket = createWebSocketConnectionVotes();
    socket.onopen = () => {
      // Send the initial message to start receiving data
      const initialMessage =
        JSON.stringify({ protocol: "json", version: 1 }) +
        String.fromCharCode(0x1e);
      socket.send(initialMessage);
    };
    socket.onmessage = async (event) => {
      const rawData = event.data;
      const messages = rawData.split(String.fromCharCode(0x1e)).filter(Boolean);
      if (messages.length > 1) {
        const parsedData = JSON.parse(messages[1]);

        if (parsedData.target === "SendStateElection") {
          if (parsedData == "Adjourned") {
            if (
              window.location.pathname.includes(
                "/ElectionContent/Election_process"
              )
            ) {
              navigate("/ElectionContent/Adjournment");
            }
          } else if (parsedData.arguments[0] == "Started") {
            if (isChairman || isPet || isSg) {
              if (
                window.location.pathname === "/ElectionContent/Election_process"
              ) {
                window.location.reload();
              } else if (window.location.pathname === "/") {
                navigate("/ElectionContent/Election_process");
              }
            }
          }
        }
      } else if (messages.length === 1) {
        const data = messages[0];
        const parsedData = JSON.parse(data);
        const typevalue = parsedData.type;

        if (typevalue === 1) {
          if (parsedData.target === "SendStateElection") {
            if (parsedData == "Adjourned") {
              if (
                window.location.pathname.includes(
                  "/ElectionContent/Election_process"
                )
              ) {
                navigate("/ElectionContent/Adjournment");
              }
            }
            if (parsedData.arguments[0] == "Started") {
              if (isChairman || isPet || isSg) {
                if (
                  window.location.pathname ===
                  "/ElectionContent/Election_process"
                ) {
                  window.location.reload();
                } else if (window.location.pathname === "/") {
                  navigate("/ElectionContent/Election_process");
                }
              }
            }
          }
        }
      }
    };
  }, [token]);
  const openPopUp = () => {
    setShowPopUp(true);
  };
  const ClosePopUp = () => {
    setShowPopUp(false);
  };
  useEffect(() => {
    if (token !== null && !onGoingElection) {
      getElectionByStatus(token, "Created").then((result) => {
        if (result) {
          setCreatedElectionExists(result);
          setElectionId(result.id);
        }
      });
    }
  }, [onGoingElection]);

  useEffect(() => {
    if (electionId !== null && token !== null) {
      getCurrentElectionYear(token, electionId)
        .then((result) => {
          setCurrentYear(result);
        })
        .catch((error) => {
          console.error("Error occurred:", error);
        });
    }
  }, [createdElectionExists]);

  function formatDate(dateString) {
    if (!dateString) {
      return "MM/DD/YYYY"; // Return "DD/MM/YYYY" if dateString is null or empty
    } else {
      const options = {
        day: "numeric",
        month: "numeric",
        year: "numeric",
        hour: "numeric",
        minute: "numeric",
        hour12: false,
      };
      const formattedDate = new Date(dateString).toLocaleString(
        "en-GB",
        options
      );
      return formattedDate; // Transform to dd/mm/yyyy format if dateString is not null
    }
  }

  const handleStartElection = async () => {
    if (!navigator.onLine) {
      notification.error({
        message: "No Internet Connection",
        description: "Please check your internet connection and try again.",
      });
      return; // Exit function if no internet connection
    }
    await startElection(token, createdElectionExists?.id);
    navigate("/ElectionContent/Election_process");
  };

  return (
    <div
      className="adjscreen"
      style={{ height: "100vh", overflow: "auto", backgroundColor: "#dae6b6" }}
    >
      <Navbar />

      {!onGoingElection && createdElectionExists ? (
        <div>
          {showPopUp && (
            <div className="PopupValidationRedirection">
              <div className="contentConfirmStartvalidationRedirection">
                <div>
                  <div className="Header">
                    <div className="TickIcon">{greenTick}</div>
                  </div>
                  <div className="Text">
                    <div>
                      <div>
                        <h5
                          style={{
                            color: "#2A2F48",
                            fontFamily: "DM Sans",
                            fontSize: "1.0625rem",
                            fontStyle: "normal",
                            fontWeight: "700",
                            lineHeight: "normal",
                          }}
                        >
                          Confirmation / Confirmation
                        </h5>
                        <p
                          style={{
                            color: "#6B6F89",
                            fontFamily: "DM Sans",
                            fontSize: "0.875rem",
                            fontStyle: "normal",
                            fontWeight: "500",
                            lineHeight: "168.7%",
                          }}
                        >
                          Êtes-vous sûr(e) de vouloir démarrer le processus
                          électoral ?
                          <br />
                          Are you sure you want to start the election process?
                        </p>
                      </div>
                      <div></div>
                    </div>
                  </div>
                  <div className="abstainButtons">
                    <button
                      className="btn btn-outline-success"
                      style={{
                        background: "#FFF", // Set background to white
                        color: "#029455", // Set text color to success color
                        borderColor: "#029455",
                        marginRight: "5px", // Set border color to success color
                      }}
                      onClick={ClosePopUp}
                    >
                      Retourner/Go Back
                    </button>
                    <button
                      className="btn btn-success"
                      style={{
                        background: "#029455",
                        color: "#FFF",
                      }}
                      onClick={handleStartElection}
                    >
                      Confirmer/Confirm
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="popupNewElection">
            <div className="contentNewElection">
              <div className="afdbDetails">
                <img src={logoImageUrl} alt="Logo" />
                <h6
                  style={{
                    color: "rgba(2, 148, 85, 1)",
                    fontFamily: "DM Sans",
                    fontSize: "21px",
                    fontWeight: "700",
                    lineHeight: "25px",
                    letterSpacing: "-0.02em",
                    marginLeft: "2%",
                  }}
                >
                  {currentYear} BAD/ADB
                </h6>
              </div>
              <div className="informingText">
                <p
                  style={{
                    color: " rgba(45, 55, 72, 1)                    ",
                    fontFamily: "DM Sans",
                    fontSize: "16px",
                    fontWeight: "700",
                    lineHeight: "26.04px",
                    marginLeft: "2%",
                  }}
                >
                  Pour démarrer le processus électoral, veuillez cliquer sur le
                  bouton <bold>'Démarrer l'élection'</bold>
                  ci-dessous/
                  <br />
                  To begin the election process, kindly click on the{" "}
                  <bold>' Start election '</bold> button below.
                </p>
              </div>
              <div className="electionDetails">
                <div
                  style={{
                    width: "0.875rem",
                    height: "10rem",
                    flexShrink: "0",
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="160"
                    viewBox="0 0 14 160"
                    fill="none"
                  >
                    <path d="M0 0H14V160H0V0Z" fill="#029455" />
                  </svg>
                </div>
                <div style={{ margin: "1.5rem" }}>
                  <p
                    style={{
                      fontFamily: "DM Sans",
                      fontSize: "21px",
                      fontWeight: "700",
                      lineHeight: "25px",
                      letterSpacing: "-0.02em",
                      color: "rgba(2, 148, 85, 1)",
                    }}
                  >
                    Details de l’élection/Election Details
                  </p>
                  <div class="row">
                    <div class="col-6">
                      <span className="detailTitle">Election Name :</span>{" "}
                      <span className="detailInfo">
                        {createdElectionExists.title}
                      </span>
                    </div>
                    <div class="col-6">
                      <span className="detailTitle">Location :</span>{" "}
                      <span className="detailInfo">
                        {createdElectionExists.location}
                      </span>
                    </div>
                    <div class="w-100" style={{ marginBottom: "2%" }}></div>
                    <div class="col-6">
                      <span className="detailTitle">
                        Planified start date :
                      </span>{" "}
                      <span className="detailInfo">
                        {formatDate(createdElectionExists.plannedStartDate)}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              {localData && (
                <div>
                  <button
                    className={
                      localData?.roles[0] === "Chairman"
                        ? "disabledButton"
                        : "electionButton"
                    }
                    onClick={openPopUp}
                    disabled={localData?.roles[0] === "Chairman"}
                  >
                    Démarrer/Start election
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div style={{}}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "5%",
              marginTop: "5%",
            }}
          >
            {" "}
            <h1 style={{ color: "#029455" }}>
              Welcome to the AfDB Electronic Voting System
            </h1>
          </div>
        </div>
      )}
    </div>
  );
};
