import { useParams } from "react-router-dom";
import { Breadcrumb } from "antd";
import Navbar from "../../components/AdminBanner";
import { useEffect, useRef, useState } from "react";
import { CheckIconInversed } from "../../assets/Icons";
import generalInformation from "./GeneralInformation";
import GeneralInformation from "./GeneralInformation";
import VotingPower from "./VotingPower";
import Voters from "./Voters";
import Candidates from "./Candidates";
import { getElectionById } from "../../services/Elections";
import { fetchToken } from "../../services/Token";
import { useNavigate } from "react-router-dom";
import { sha256 } from "js-sha256";
import { RollbackOutlined } from "@ant-design/icons";

const EditElection = () => {
  const secretKey = process.env.REACT_APP_SECRET_KEY;
  const navigate = useNavigate();
  const hashValue = (value) => {
    return sha256.hmac(secretKey, value.toString());
  };

  const handleEndCreateElection = () => {
    // Redirect to "/"
    navigate("/");
  };

  const checkEditMode = (value) => {
    const hashedValue = hashValue(value);
    const storedHashedValue = localStorage.getItem("editMode");
    return hashedValue === storedHashedValue;
  };
  const [selectedButton, setSelectedButton] = useState(1);
  const { electionId } = useParams();
  const [EditModeTest, setEditModeTest] = useState(false);
  const [localData, setLocalData] = useState(null);
  const isEditMode =
    new URLSearchParams(window.location.search).get("editMode") === "true";
  const [token, setToken] = useState(null);
  const [electionData, setElectionData] = useState(null);
  useEffect(() => {}, []);
  useEffect(() => {
    const response = checkEditMode(isEditMode);
    if (!response) {
      navigate("/accessDenied");
    }
    setEditModeTest(isEditMode);
    let idTokenClaims = JSON.parse(localStorage.getItem("idTokenClaims"));
    setLocalData(idTokenClaims);
    fetchToken(JSON.parse(localStorage.getItem("idTokenClaims"))).then(
      (result) => {
        setToken(result);
      }
    );
  }, []);

  useEffect(() => {
    if (token !== null) {
      getElectionById(token, electionId).then((result) => {
        setElectionData(result);
      });
    }
  }, [token, selectedButton]);
  useEffect(() => {
    if (localData !== null) {
      if (!localData?.roles?.includes("Admin")) {
        navigate("/AccessDenied");
      }
    }
  }, [localData]);

  const lastItemStyle = {
    color: "#029455",
    fontFamily: "DM Sans",
    fontStyle: "normal",
    fontWeight: "500",
    cursor: "default",
  };

  const handleButtonClick = (buttonIndex) => {
    setSelectedButton(buttonIndex);
  };

  const buttonStyles = {
    width: "25%",
    height: "100%",
    backgroundColor: "white",
    color: "#029455",
    border: "none",
    fontFamily: "DM Sans",
    fontSize: "1rem",
    fontStyle: "normal",
    fontWeight: "550",
    lineHeight: "120%",
  };

  const selectedButtonStyles = {
    ...buttonStyles,
    backgroundColor: "#029455",
    color: "white",
  };
  return (
    <div className="container-fluid" style={{ backgroundColor: "#FAFBFC" }}>
      <div className="row">
        <Navbar />
      </div>
      <div className="d-flex align-items-center" style={{ height: "5vh" }}>
        <Breadcrumb separator=">" style={{ color: "rgba(0, 0, 0, 0.45)" }}>
          <Breadcrumb.Item>
            <a style={{ cursor: "default" }}>Election</a>
          </Breadcrumb.Item>
          <Breadcrumb.Item style={lastItemStyle}>
            <a style={lastItemStyle}>
              {selectedButton == 1
                ? "General information"
                : selectedButton == 2
                ? "Voting Power"
                : selectedButton == 3
                ? "Voters"
                : "Candidates"}
            </a>
          </Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div style={{ height: "83vh" }}>
        <div
          style={{
            height: "8%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "10px",
          }}
        >
          <p
            style={{
              marginRight: "10px",
              fontFamily: "DM Sans",
              fontSize: "1rem",
              fontStyle: "normal",
              fontWeight: "700",
              lineHeight: "140%",
              color: "2D3748",
              marginLeft: "2%",
            }}
          >
            Election creation
          </p>
          <p
            style={{
              marginRight: "2.3rem",
              fontFamily: "DM Sans",
              fontSize: "1rem",
              fontStyle: "normal",
              fontWeight: "700",
              lineHeight: "140%",
              color: "2D3748",
            }}
          >
            {electionData?.title}
          </p>
        </div>
        <div
          className="tabulation"
          style={{
            height: "22%",
            margin: "0 1.7rem",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: "#FFF",
          }}
        >
          <div
            className="Tabs-btn"
            style={{
              backgroundColor: "white",
              width: "60%",

              height: "46px",
              borderRadius: "10px",
              overflow: "hidden",
              border: " 1px solid #00000026",
              marginLeft: "1%",
            }}
          >
            <button
              style={{
                ...(selectedButton === 1 ? selectedButtonStyles : buttonStyles),
                borderRight: "1px solid #ccc",
              }}
              onClick={() => handleButtonClick(1)}
            >
              {CheckIconInversed} General Information
            </button>
            <button
              style={{
                ...(selectedButton === 2 ? selectedButtonStyles : buttonStyles),
                borderRight: "1px solid #ccc",
              }}
              onClick={() => handleButtonClick(2)}
            >
              {CheckIconInversed} Voting Power
            </button>
            <button
              style={{
                ...(selectedButton === 3 ? selectedButtonStyles : buttonStyles),
                borderRight: "1px solid #ccc",
              }}
              onClick={() => handleButtonClick(3)}
            >
              {CheckIconInversed} Voters
            </button>
            <button
              style={{
                ...(selectedButton === 4 ? selectedButtonStyles : buttonStyles),
                borderRight: "1px solid #ccc",
              }}
              onClick={() => handleButtonClick(4)}
            >
              {CheckIconInversed} Candidates
            </button>
          </div>

          <button
            type="button"
            className="btn btn-forlist"
            onClick={handleEndCreateElection}
          >
            List View <RollbackOutlined />
          </button>
        </div>
        <div style={{ height: "80%" }}>
          {selectedButton && selectedButton === 1 ? (
            <GeneralInformation
              electionData={electionData}
              setElectionData={setElectionData}
              token={token}
              EditModeTest={EditModeTest}
            />
          ) : selectedButton === 2 ? (
            <VotingPower EditModeTest={EditModeTest} />
          ) : selectedButton === 3 ? (
            <Voters EditModeTest={EditModeTest} />
          ) : (
            selectedButton === 4 && <Candidates EditModeTest={EditModeTest} />
          )}
        </div>
      </div>
    </div>
  );
};
export default EditElection;
