import React, { useEffect, useState } from "react";
import Navbar from "../../components/AdminBanner";
import { Breadcrumb, notification } from "antd";
import {
  GreenClock,
  GreyClose,
  greenRefresh,
  greenTick,
  redRestrict,
} from "../../assets/Icons";
import "./ROValidation.css";
import CircleComponent from "../AdminSubComponents/ElectionProcess/CircleComponent";
import { getRoundsByElection } from "../../services/Rounds";
import { fetchToken } from "../../services/Token";
import { getCurrentElectionID } from "../../services/Elections";
import { getVotersByRound } from "../../services/Voters";
import { getReturningOfficerStats, getVoteStats } from "../../services/Stats";
import { ValidateResults, getBusinessLog } from "../../services/BusinessLog";
import { useNavigate } from "react-router-dom";
export const ROValidationForSG = () => {
  const [token, setToken] = useState(null);
  const [localData, setLocalData] = useState(null);
  const [rounndId, setRounndId] = useState();
  const [roundNumber, setRoundNumber] = useState(null);
  const [electionId, setElectionId] = useState();
  const [votersPerRound, setVotersPerRound] = useState(null);
  const [stat, setStat] = useState(null);
  const [returningOfficerStat, setReturningOfficerStat] = useState(null);
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [showPopupValidate, setShowPopupValidate] = useState(false);
  const [formattedStartTime, setFormattedStartTime] = useState(null);
  const [formattedEndTime, setFormattedEndTime] = useState(null);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [resultsPosted, setResultsPosted] = useState(false);
  const [isSg, setIsSg] = useState(false);
  const [electionLoading, setElectionLoading] = useState(true);
  const [roundLoading, setRoundLoading] = useState(true);

  let totalVotingPower = 0;
  const navigate = useNavigate();
  useEffect(() => {
    let idTokenClaims = JSON.parse(localStorage.getItem("idTokenClaims"));
    setLocalData(idTokenClaims);
  }, []);

  useEffect(() => {
    if (localData !== null) {
      //  console.log(localData.preferred_username);
      fetchToken(localData).then((result) => {
        setToken(result);
      });
      if (!localData?.roles?.includes("SG")) {
        navigate("/AccessDenied");
      }
    }
  }, [localData]);

  useEffect(() => {
    if (token !== null) {
      getCurrentElectionID(token)
        .then((result) => {
          if (result?.id) {
            setElectionId(result.id);
            setElectionLoading(false);
          } else {
            setIsButtonDisabled(true);
            setElectionLoading(false);
          }
        })
        .catch((error) => {
          console.error("Error fetching current election ID:", error);
          setElectionLoading(false);
        });
    }
  }, [token]);

  function formatDate(dateString) {
    if (!dateString) {
      return "MM/DD/YYYY"; // Return "DD/MM/YYYY" if dateString is null or empty
    } else {
      const options = {
        day: "numeric",
        month: "numeric",
        year: "numeric",
        hour: "numeric",
        minute: "numeric",
        hour12: false,
      };
      const formattedDate = new Date(dateString).toLocaleString(
        "en-GB",
        options
      );
      return formattedDate; // Transform to dd/mm/yyyy format if dateString is not null
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (token !== null && electionId != null) {
          // Get all rounds in the election
          const roundsByElection = await getRoundsByElection(token, electionId);

          // If there's only one round in the election
          if (roundsByElection.length === 1) {
            const onlyRound = roundsByElection[0];
            // If the only round is closed, set the necessary states for displaying the data
            if (onlyRound.status === "Closed") {
              setRoundNumber(onlyRound.number);
              setRounndId(onlyRound.id);
              setStartTime(onlyRound.startTime);
              setEndTime(onlyRound.endTime);
            } else {
              setIsButtonDisabled(true);
              // Do not display anything if the only round is not closed
            }
          } else if (roundsByElection.length > 1) {
            // If there are multiple rounds, find the last closed round
            const lastClosedRound = roundsByElection
              .filter((round) => round.status === "Closed")
              .pop(); // Get the last closed round            );
            if (lastClosedRound) {
              // Display the last closed round
              setRoundNumber(lastClosedRound.number);
              setRounndId(lastClosedRound.id);
              setStartTime(lastClosedRound.startTime);
              setEndTime(lastClosedRound.endTime);
            } else {
              setIsButtonDisabled(true);
            }
          }
          setRoundLoading(false);
        }
      } catch (error) {
        console.error("Error fetching round data:", error);
      }
    };

    fetchData();
  }, [token, electionId]);

  useEffect(() => {
    if (rounndId != null && !isButtonDisabled) {
      setRoundLoading(false);
      getVotersByRound(token, rounndId).then((result) => {
        setVotersPerRound(result?.data);
      });
    }
  }, [rounndId]);

  useEffect(() => {
    setFormattedStartTime(formatDate(startTime));
    setFormattedEndTime(formatDate(endTime));
  }, [startTime, endTime]);

  useEffect(() => {
    if (
      token !== null &&
      electionId !== null &&
      rounndId != null &&
      !isButtonDisabled
    ) {
      getVoteStats(token, electionId, rounndId).then((result) => {
        setStat(result);
      });
      getReturningOfficerStats(token, electionId, rounndId).then((result) => {
        setReturningOfficerStat(result);
      });
    }
  }, [token, electionId, rounndId]);

  function getCurrentDate() {
    const currentDate = new Date();
    const isoString = currentDate.toISOString(); // Get ISO string in the format "YYYY-MM-DDTHH:mm:ss.sssZ"
    const formattedDate = isoString.split("T")[0] + "T00:00:00"; // Keep only the date part and set the time to "00:00:00"
    return formattedDate;
  }

  const handleButtonClickValidate = () => {
    setShowPopupValidate(true);
  };

  useEffect(() => {
    const checkIfResultsPosted = async () => {
      try {
        const results = await getBusinessLog(token, rounndId);

        // Filter the results based on the "created by" property
        const filteredResults = results.filter(
          (result) => result.createdBy === localData.preferred_username
        );

        // Check if there are any filtered results
        const resultsPosted = filteredResults.length > 0;

        // Update the state accordingly
        setResultsPosted(resultsPosted);
      } catch (error) {
        console.error("Error checking results:", error);
        // Handle error as needed
      }
    };

    // When the component mounts, check if results are already posted
    if (token !== null && rounndId != null) {
      checkIfResultsPosted();
    }
  }, [rounndId]);

  useEffect(() => {
    // Watch for changes in the resultsPosted state and update isButtonDisabled accordingly
    setIsButtonDisabled(resultsPosted);
  }, [resultsPosted]);

  const handleButtonClickConfirmValidate = async () => {
    const sysdate = getCurrentDate();
    // console.log(sysdate);
    setShowPopupValidate(false);

    if (!resultsPosted) {
      setIsButtonDisabled(true);
      try {
        const response = await ValidateResults(
          token,
          rounndId,
          sysdate,
          localData.preferred_username
        );
        if (response.status === 200) {
          notification.success({
            description: `Round validated successfully!`,
          });
        } else {
          notification.error({
            description: "Error",
          });
        }
        setResultsPosted(true);
      } catch (error) {
        console.error("Error validating results:", error);
      }
    }
  };
  const handlePopupClose = () => {
    setShowPopupValidate(false);
  };
  const formatResult = (result) => {
    const formattedresult = parseFloat(result).toFixed(2);
    return formattedresult;
  };

  function formatToThreeDecimals(number) {
    let [integerPart, decimalPart] = number.toString().split(".");

    if (!decimalPart) {
      decimalPart = "000";
    } else if (decimalPart.length < 3) {
      decimalPart = decimalPart.padEnd(3, "0");
    } else if (decimalPart.length > 3) {
      decimalPart = decimalPart.slice(0, 3);
    }

    return `${integerPart}.${decimalPart}`;
  }
  const formatPercentage = (percentage) => {
    const formattedPercentage = parseFloat(percentage).toFixed(3);
    const correctedNumber = Object.is(formattedPercentage, -0)
      ? 0
      : formattedPercentage;
    return correctedNumber;
  };

  const handleRefresh = () => {
    window.location.reload();
  };

  return (
    <div
      className="container-fluid rovinterface"
      style={{ backgroundColor: "#dae6b6", height: "100vh", overflow: "auto" }}
    >
      <div className="row">
        <div className="col Rodvalid">
          <Navbar />
        </div>
      </div>

      <div className="row" style={{ height: "15vh" }}>
        {/**BREADCRUMB COMPONENT*/}
        <div className="d-flex align-items-center">
          <Breadcrumb separator=">" style={{ color: "rgba(0, 0, 0, 0.45)" }}>
            <Breadcrumb.Item>
              <a style={{ color: "rgba(0, 0, 0, 0.45)", cursor: "default" }}>
                Returning officers
              </a>
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>
        {electionLoading && roundLoading && (
          <div
            className="container-fluid"
            style={{ height: "26vh", margin: "4rem" }}
          >
            <div
              className="spinner-border text-success"
              role="status"
              style={{
                width: "70px",
                height: "70px",
              }}
            ></div>
          </div>
        )}
        {!electionLoading && roundLoading && !electionId && (
          <div
            style={{
              margin: "4rem",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            {redRestrict}
            <h6
              style={{
                fontFamily: "DM Sans",
                fontSize: "22px",
                fontWeight: "700",
                lineHeight: "31px",
                letterSpacing: "0em",
                marginBottom: "1rem",
                marginTop: "1rem",
              }}
            >
              Patientez! Le vote n'a pas commencé / Hold on! Election has not
              yet started
            </h6>
            <p
              style={{
                fontFamily: "DM Sans",
                fontSize: "19px",
                fontWeight: "400",
                lineHeight: "28.64px",
                letterSpacing: "0em",
              }}
            >
              Nous vous informons qu'aucune élection n'est actuellement en
              cours. Veuillez vous abstenir de procéder d'avantage jusqu'au
              commencement officiel du processus électoral /
              <br />
              No election is currently in progress. Kindly refrain from
              proceeding further until the official commencement of the election
              process.
            </p>
          </div>
        )}
        {!electionLoading && !roundLoading && !rounndId && (
          <div
            className="row"
            style={{
              margin: "4rem",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            {redRestrict}
            <h6
              style={{
                fontFamily: "DM Sans",
                fontSize: "22px",
                fontWeight: "700",
                lineHeight: "31px",
                letterSpacing: "0em",
                marginBottom: "1rem",
                marginTop: "1rem",
              }}
            >
              Patientez! le premier tour des élections est en cours / Hold on!
              first round of election is ongoing
            </h6>
            <p
              style={{
                fontFamily: "DM Sans",
                fontSize: "19px",
                fontWeight: "400",
                lineHeight: "28.64px",
                letterSpacing: "0em",
              }}
            >
              Le vote est actuellement en cours pour le premier tour de
              l'élection. Il vous sera demandé de procéder à la validation sous
              peu. /
              <br />
              The vote is currently ongoing for the first round of the election.
              You will be requested to proceed with the validation shortly
            </p>
          </div>
        )}
        {!electionLoading && !roundLoading && electionId && rounndId && (
          <div className="col" style={{ height: "10vh", width: "60rem" }}>
            <div style={{ gap: "2.375rem", height: "8vh" }}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div className="roundNumber">
                  Tour N:{roundNumber} / Round N:{roundNumber}
                  <p style={{ marginLeft: "1rem" }}> {greenRefresh} </p>
                  <p className="refreshText" onClick={handleRefresh}>
                    {" "}
                    Refresh{" "}
                  </p>
                </div>
                <div className="timeComponant">
                  <span style={{ marginRight: "10px" }}>{GreenClock}</span>
                  <span>
                    Start Time: {formattedStartTime} - End Time:{" "}
                    {formattedEndTime}
                  </span>
                </div>
              </div>
            </div>
            <div>
              <div
                className="row"
                style={{ backgroundColor: "#dae6b6", height: "80vh" }}
              >
                {/* Table Component */}
                <div className="col-12  col-xl-9">
                  <div className="w-100" style={{ marginLeft: "0.5rem" }}>
                    <div style={{ display: "flex", marginBottom: "1rem" }}>
                      <div className="greenStat">
                        <span className="text">
                          Nombre des pays participants/
                          <br />
                          Number of participants countries
                          <br />
                        </span>
                        <span className="number">
                          {returningOfficerStat?.participantCountries}
                        </span>
                      </div>
                      <div className="greenStat">
                        <span className="text">
                          Nombre des votes exprimés/
                          <br />
                          Number of votes cast
                          <br />
                        </span>
                        <span className="number">
                          {returningOfficerStat?.votes}
                        </span>
                      </div>
                      <div className="greenStat">
                        <span className="text">
                          Nombre d'abstention/
                          <br />
                          Number of abstention
                          <br />
                        </span>
                        <span className="number">
                          {returningOfficerStat?.abstention}
                        </span>
                      </div>
                      <div className="greenStat">
                        <span className="text">
                          Nombre des pays régionaux/
                          <br />
                          Number of Regional Countries
                          <br />
                        </span>
                        <span className="number">
                          {returningOfficerStat?.regionalCountries}
                        </span>
                      </div>
                    </div>
                    <div
                      style={{
                        border: "2px solid white",
                        borderRadius: "1.25rem",
                        padding: "0.5rem",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginTop: "10px",
                          marginBottom: "20px",
                          padding: "0.5rem",
                        }}
                      >
                        <h4 className="VotingTitle">Dashboard</h4>
                      </div>
                      <div
                        style={{
                          maxHeight: "calc(100vh - 499px)",
                          overflowY: "auto",
                        }}
                      >
                        <table className="w-100">
                          <thead>
                            <tr>
                              <th>
                                ID de pays
                                <br />
                                Country ID
                              </th>
                              <th>
                                Regional/Non Regional
                                <br />
                                Regional/Non Regional
                              </th>
                              <th>
                                Vote
                                <br />
                                Vote
                              </th>
                              <th>
                                Candidat
                                <br />
                                Candidate
                              </th>
                              <th>
                                Pouvoir de vote
                                <br />
                                Voting power
                              </th>
                            </tr>
                          </thead>

                          <tbody className="BodyText">
                            {votersPerRound?.map((item, index) => {
                              totalVotingPower +=
                                item.voters.country.votingPower[0]?.power || 0;
                              return (
                                <tr
                                  key={index}
                                  className={
                                    item.voters.country.regional
                                      ? "yellow-row"
                                      : "green-row"
                                  }
                                  style={{ borderBottom: "1px solid #E3E8C4" }}
                                >
                                  <td style={{ padding: "1rem" }}>
                                    {item.voters.country.id}
                                  </td>
                                  {item.voters.country.regional ? (
                                    <td>Regional/Regional</td>
                                  ) : (
                                    <td>Non Regional/Non Regional</td>
                                  )}
                                  <td>
                                    {item.voters.votes.length > 0 &&
                                    item.voters.votes.some(
                                      (vote) => vote.roundID === rounndId
                                    )
                                      ? "Oui/Yes"
                                      : "Non/No"}
                                  </td>
                                  <td>
                                    {item.voters.votes.length > 0
                                      ? item.voters.votes.some(
                                          (vote) => vote.roundID === rounndId
                                        )
                                        ? item.voters.votes[
                                            item.voters.votes.length - 1
                                          ].candidateID !== null
                                          ? item.voters.votes[
                                              item.voters.votes.length - 1
                                            ].candidate.firstName
                                          : "Abstention"
                                        : ""
                                      : ""}{" "}
                                    {item.voters.votes.length > 0
                                      ? item.voters.votes.some(
                                          (vote) => vote.roundID === rounndId
                                        )
                                        ? item.voters.votes[
                                            item.voters.votes.length - 1
                                          ].candidateID !== null
                                          ? item.voters.votes[
                                              item.voters.votes.length - 1
                                            ].candidate.lastName
                                          : ""
                                        : ""
                                      : ""}
                                  </td>
                                  <td>
                                    {item.voters.country.votingPower[0]
                                      ?.power !== undefined
                                      ? formatToThreeDecimals(
                                          item.voters.country.votingPower[0]
                                            .power
                                        )
                                      : formatToThreeDecimals(0)}{" "}
                                    %
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="votingPower">
                      Total de pouvoir de vote:{" "}
                      {formatPercentage(totalVotingPower)} %
                      <br />
                      Total voting power: {formatPercentage(totalVotingPower)} %
                    </div>
                  </div>
                </div>

                {/* STATS COMPONENT */}
                <div
                  className="col-12 resp-card col-xl-3"
                  style={{ height: "100%" }}
                >
                  {/* COMPONENT 1 */}
                  <div
                    style={{
                      border: "2px solid white",
                      marginBottom: "4%",
                      borderRadius: "1.25rem",
                      paddingRight: "0.75rem",
                      paddingLeft: "0.75rem",
                    }}
                  >
                    <div>
                      <p className="StatTitle">Bulletin null / Void Ballot</p>
                    </div>
                    <div className="d-flex justify-content-around">
                      <CircleComponent
                        number={
                          stat ? formatResult(stat.voidBallots) + "%" : "0.00%"
                        }
                      />
                    </div>
                  </div>

                  {/* COMPONENT 2 */}
                  <div
                    style={{
                      border: "2px solid white",
                      marginBottom: "4%",
                      borderRadius: "1.25rem",
                      paddingRight: "0.75rem",
                      paddingLeft: "0.75rem",
                    }}
                  >
                    <div style={{ height: "18%" }}>
                      <p className="StatTitle">
                        Pourcentage du total des votes exprimés / Percentage of
                        Total votes cast
                      </p>
                    </div>
                    <div className="d-flex justify-content-around">
                      <CircleComponent
                        pourcentage={
                          stat
                            ? formatResult(stat.pourcentageVotingPower) + "%"
                            : "0.00%"
                        }
                      />
                    </div>
                  </div>

                  {/* COMPONENT 3 */}
                  <div
                    style={{
                      marginBottom: "4%",

                      border: "2px solid white",
                      borderRadius: "1.25rem",
                      paddingRight: "0.75rem",
                      paddingLeft: "0.75rem",
                    }}
                  >
                    <div>
                      <p className="StatTitle">
                        Pourcentage des votes régionaux / Percentage of Regional
                        votes{" "}
                      </p>
                    </div>
                    <div
                      className="d-flex justify-content-around"
                      style={{ height: "80%" }}
                    >
                      <CircleComponent
                        pourcentage={
                          stat
                            ? formatResult(stat.pourcentageRegional) + "%"
                            : "0.00%"
                        }
                      />
                    </div>
                  </div>
                  {/* COMPONENT 4 */}
                  <div
                    style={{
                      border: "2px solid white",
                      borderRadius: "1.25rem",
                      paddingRight: "0.75rem",
                      paddingLeft: "0.75rem",
                    }}
                  >
                    <div>
                      <p className="StatTitle">
                        Pourcentage du total des abstentions / Percentage of
                        Total abstention
                      </p>
                    </div>
                    <div className="d-flex justify-content-around">
                      <CircleComponent
                        pourcentage={
                          stat
                            ? formatResult(stat.pourcentageAbstention) + "%"
                            : "0.00%"
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      {showPopupValidate && (
        <div className="PopupConfirmStart">
          <div className="contentConfirmStart">
            <div>
              <div className="Header">
                <div className="TickIcon">{greenTick}</div>
                <button
                  style={{ border: "none", backgroundColor: "#FFF" }}
                  onClick={handlePopupClose}
                >
                  {GreyClose}
                </button>
              </div>
              <div className="Text">
                <div>
                  <div>
                    <h6>Validation des Résultats / Results Validation</h6>
                  </div>
                  <div>
                    <p className="informingText">
                      Êtes-vous sûr(e) de vouloir procéder et valider les
                      résultats ?
                      <br />
                      Are you sure you want to proceed and validate the results?
                    </p>
                  </div>
                </div>
              </div>
              <div className="abstainButtons">
                <button
                  className="buttonChange"
                  style={{ border: "1px solid #029455", color: "#029455" }}
                  onClick={handlePopupClose}
                >
                  Retourner/Go back
                </button>
                <button
                  className="buttonConfirm"
                  style={{ background: "#029455", color: "#FFF" }}
                  onClick={handleButtonClickConfirmValidate}
                >
                  Confirmer/Confirm
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
