import React, { useState, useEffect } from "react";
import Navbar from "react-bootstrap/Navbar";
import { useIsAuthenticated } from "@azure/msal-react";
import { SignInButton } from "./SignInButton";
import { SignOutButton } from "./SignOutButton";
import { Link } from "react-router-dom";
import { HomeOutlined, UserOutlined } from "@ant-design/icons";
import { useMsal } from "@azure/msal-react";
import axios from "axios";
import { fetchToken } from "../services/Token";
import { getVoterCountryStaus } from "../services/Voters";
import { useLocation } from "react-router-dom";

export const PageLayout = (props) => {
  let idTokenClaims = null;
  idTokenClaims = JSON.parse(localStorage.getItem("idTokenClaims"));
  const { accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const [delayedRender, setDelayedRender] = useState(false);
  const [clientInfo, setClientInfo] = useState(null);

  const isAdmin =
    accounts[0]?.idTokenClaims?.roles?.includes("Admin") ||
    accounts[0]?.idTokenClaims?.roles?.includes("PET");
  const isItAdmin = idTokenClaims?.roles?.includes("ITAdmin");
  const location = useLocation();
  const currentPath = location.pathname;
  const domain = "login.windows.net";

  useEffect(() => {
    const timer = setTimeout(() => {
      setDelayedRender(true);
    }, 1000);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  useEffect(() => {
    if (delayedRender) {
      fetchToken(idTokenClaims)
        .then((result) => {
          // Update the state with the resolved value
          setClientInfo(result);
          localStorage.setItem("Token", clientInfo);
        })
        .catch((error) => {
          console.error("Error fetching token:", error);
        });
    }
  }, [delayedRender]);

  const adminIconStyle = {
    fontSize: "20px",
    color: "blue",
    marginRight: "5px",
  };
  return (
    <div>
      {delayedRender && !isAuthenticated ? <SignInButton /> : null}
      {isAdmin && (
        <>
          {currentPath == "/" && isItAdmin && (
            <Navbar
              className="navbarStyle"
              style={{ height: "50px", backgroundColor: "darkgray" }}
            >
              <div
                className="d-flex align-items-center justify-content"
                style={{ width: "85%" }}
              >
                <p
                  className="navbar-brand"
                  href="/"
                  style={{ paddingTop: "15px" }}
                >
                  Evoting Platform
                </p>
                <Link
                  to="/Home"
                  className="nav-link mr-auto d-flex align-items-center"
                  style={{ color: "white" }}
                >
                  <HomeOutlined style={{ marginRight: "5px" }} /> Home
                </Link>
                {isAdmin && (
                  <Link
                    // to="/AdminOnly"
                    to="/"
                    className="nav-link d-flex align-items-center"
                    style={{ color: "white", marginLeft: "10px" }}
                  >
                    <UserOutlined style={adminIconStyle} />
                    Admin Only
                  </Link>
                )}
              </div>
              <div style={{ marginRight: "5%" }}>
                <SignOutButton />
              </div>
            </Navbar>
          )}
        </>
      )}
      <div className="profileContent">{props.children}</div>
    </div>
  );
};
