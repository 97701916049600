import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import * as XLSX from "xlsx";
import {
  GreyClose,
  ShadowedTrash,
  TrashIcon,
  UploadCloud,
  Vector,
} from "../../assets/Icons";
import { Spinner } from "react-bootstrap";
import {
  PostCountriesVotingPower,
  deleteCountryByElectionID,
  getCountryByElectionID,
} from "../../services/Countries";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { fetchToken } from "../../services/Token";
import { useParams } from "react-router-dom";
import { notification } from "antd";
import Decimal from "decimal.js";

const VotingPower = ({ EditModeTest }) => {
  const [uploadedData, setUploadedData] = useState(null);
  const [uploadStatus, setUploadStatus] = useState(null);
  const [uploadedSize, setUploadedSize] = useState(0);
  const [totalSize, setTotalSize] = useState(0);
  const [fileName, setFileName] = useState("");
  const { electionId } = useParams();
  const [token, setToken] = useState(null);
  const [localData, setLocalData] = useState(null);
  const [isFromBackend, setIsFromBackend] = useState(false);
  const [DataToPost, setDataToPost] = useState(null);
  const [dateOfImport, setDateOfImport] = useState(null);
  const [showPopupDeleteVotingPower, setShowPopupDeleteVotingPower] =
    useState(false);
  const [disableButton, setDisableButton] = useState(false);
  useEffect(() => {
    let idTokenClaims = JSON.parse(localStorage.getItem("idTokenClaims"));
    setLocalData(idTokenClaims);
  }, []);

  useEffect(() => {
    if (localData !== null) {
      fetchToken(localData).then((result) => {
        setToken(result);
      });
    }
  }, [localData]);

  useEffect(() => {
    if (token !== null) {
      getCountryByElectionID(token, electionId).then((result) => {
        if (
          Array.isArray(result.data) &&
          result.data.length > 0 &&
          result.status === 200
        ) {
          setUploadStatus("success");
          setUploadedData(result.data);
          setIsFromBackend(true);
          setDateOfImport(result?.data[1]?.votingPower[0]?.createdDate);
        } else {
          setUploadStatus("before");
        }
      });
    }
  }, [token]);

  const onSaveButtonClick = async () => {
    if (!navigator.onLine) {
      notification.error({
        message: "No Internet Connection",
        description: "Please check your internet connection and try again.",
      });
      return; // Exit function if no internet connection
    }

    try {
      setDisableButton(true);
      const convertedData = uploadedData.slice(1).map((row) => {
        // Ensure row[2] is properly handled
        let powerString = "0";
        if (row[2] !== undefined && row[2] !== null) {
          powerString = String(row[2]).trim();
        }
        const powerValue = parseFloat(powerString);
        // Format to two decimal places and keep it as a string
        const formattedPower = isNaN(powerValue) ? "0.00" : powerValue;

        return {
          electionID: electionId,
          title: row[0],
          regional: row[1]?.trim().toLowerCase() === "true",
          votingPower: [
            {
              electionID: electionId,
              power: formattedPower,
              createdDate: new Date().toISOString(),
            },
          ],
        };
      });
      const response = await PostCountriesVotingPower(token, convertedData);
      if (response.status === 200) {
        getCountryByElectionID(token, electionId).then((result) => {
          if (
            Array.isArray(result.data) &&
            result.data.length > 0 &&
            result.status === 200
          ) {
            setUploadStatus("success");
            setUploadedData(result.data);
            setIsFromBackend(true);
          }
        });
        notification.success({
          description: `Data was saved successfully.`,
        });
      } else {
        notification.error({
          description: `Failed to save data. Please try again.`,
        });
      }
    } catch (error) {
      console.error("Error saving data:", error);
      notification.error({
        description: `Failed to save data. Please try again.`,
      });
    }
  };

  const onDrop = (acceptedFiles) => {
    const file = acceptedFiles[0];
    if (
      file.name.split(".")[1] !== "xlsx" &&
      file.name.split(".")[1] !== "csv"
    ) {
      notification.error({
        description: `Please upload a valid file type (xlsx or csv).`,
        placement: "topRight",
        duration: 3,
      });
      return;
    }
    setFileName(file.name);
    const reader = new FileReader();

    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: "array" });
      // Assuming you want the first sheet from the workbook
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

      // Check for duplicates in column one
      const seen = new Set();
      let hasDuplicates = false;
      let hasNull = false;
      for (let i = 1; i < jsonData.length; i++) {
        const value = jsonData[i][0]?.toString().toLowerCase(); // assuming column one is the first column
        const valueBool = jsonData[i][1]?.toLowerCase();
        if (valueBool != "true" && valueBool != "false") {
          hasNull = true;
          break;
        }
        if (seen.has(value)) {
          hasDuplicates = true;
          break;
        }
        seen.add(value);
      }

      // Check if total of rows = 100 based on column three
      let total = 0;
      for (let i = 1; i < jsonData.length; i++) {
        const value = parseFloat(jsonData[i][2]) * 1000000000000000;
        if (!isNaN(value)) {
          total += value / 1000000000000000;
        }
      }

      const totalRowsValid = total === 100;
      if (hasDuplicates) {
        setUploadStatus(
          "error : Duplicate countries detected. Please ensure each country is uploaded only once."
        );
        console.error(
          "Duplicate countries detected. Please ensure each country is uploaded only once."
        );
        notification.error({
          description: `Duplicate countries detected. Please ensure each country is uploaded only once.`,
        });
        setUploadStatus("before");
      } else if (!totalRowsValid) {
        setUploadStatus(
          "error: Total of voting power should be equal to 100% "
        );
        console.error("Total of voting power should be equal to 100% ");
        notification.error({
          description: `Total of voting power should be equal to 100% `,
        });
        setUploadStatus("before");
      } else if (hasNull) {
        notification.error({
          message: "Missing or Unauthorized Type Detected",
          description: `Please ensure that all countries are correctly entered and have the appropriate type before proceeding.`,
        });
        setUploadStatus("before");
      } else {
        // Convert all values in column one to uppercase
        const modifiedData = jsonData.map((row, index) => {
          if (index === 0) return row; // Skip header row
          return [row[0]?.toString().toUpperCase(), ...row.slice(1)];
        });

        setUploadedData(modifiedData);
        setUploadStatus("success");
      }
    };

    reader.onprogress = (e) => {
      setUploadedSize(e.loaded);
      setTotalSize(e.total);
    };

    reader.readAsArrayBuffer(file);
    setDateOfImport(new Date());
  };

  const handleDeleteClick = () => {
    setShowPopupDeleteVotingPower(true);
  };

  const handlePopupDeleteVotingPowerClose = () => {
    setShowPopupDeleteVotingPower(false);
  };

  const removeFile = () => {
    if (!navigator.onLine) {
      notification.error({
        message: "No Internet Connection",
        description: "Please check your internet connection and try again.",
      });
      return; // Exit function if no internet connection
    }
    setShowPopupDeleteVotingPower(false);
    setDisableButton(false);
    if (isFromBackend) {
      deleteCountryByElectionID(token, electionId)
        .then((response) => {
          if (response.status === 204) {
            setUploadedData(null);
            setUploadStatus("before");
            setUploadedSize(0);
            setTotalSize(0);
            setDateOfImport(null);
            setIsFromBackend(false);
            notification.success({
              description: `Countries & Voting Powers were successfully deleted.`,
            });
          } else {
            notification.error({
              description: `${response.data.detail}.`,
            });
          }
        })
        .catch((error) => {
          notification.error({
            description: `Failed to remove file.`,
          });
        });
    } else {
      setUploadedData(null);
      setUploadStatus("before");
      setUploadedSize(0);
      setTotalSize(0);
      setDateOfImport(null);
    }
  };

  const formatPowerValue = (value) => {
    return value;
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop });
  return (
    <div
      className=""
      style={{
        height: "100%",
        marginLeft: "2%",
        marginRight: "2%",
        backgroundColor: "#FFF",
      }}
    >
      <div
        style={{ backgroundColor: "rgba(208, 208, 208, 0.07)", height: "80%" }}
      >
        <div className="d-flex align-items-center" style={{ height: "15%" }}>
          <div style={{ marginLeft: "2%" }}>{Vector}</div>
          <p
            style={{
              marginLeft: "0.5%",
              marginTop: "1%",
              color: "#2D3748",
              fontFamily: "DM Sans",
              fontSize: "23px",
              fontStyle: "normal",
              fontWeight: "700",
              lineHeight: "140%",
            }}
          >
            Voting Power
          </p>
        </div>
        <div style={{ marginLeft: "2%" }}>
          {uploadStatus === "success" ? (
            <div>
              <p
                style={{
                  color: "#029455",
                  fontFeatureSettings: "'clig' off,'liga' off",
                  fontFamily: "DM Sans",
                  fontSize: "1.01rem",
                  fontStyle: "normal",
                  fontWeight: "700",
                  lineHeight: "1rem",
                }}
              >
                {!isFromBackend &&
                  `File "${fileName}" has been successfully uploaded.`}
              </p>
              <p
                style={{
                  color: "#344054",
                  fontFamily: "DM Sans",
                  fontSize: "1rem",
                  fontStyle: "normal",
                  fontWeight: "500",
                  lineHeight: "1rem",
                  marginTop: "1%",
                }}
              >
                Date of Import :{" "}
                {dateOfImport?.toLocaleString("en-US", {
                  timeZone: "Europe/Paris",
                })}
              </p>
            </div>
          ) : (
            // Default content when uploadStatus is not success
            <>
              <p
                style={{
                  color: "#2B3674",
                  fontFeatureSettings: "'clig' off,'liga' off",
                  fontFamily: "DM Sans",
                  fontSize: "1.01rem",
                  fontStyle: "normal",
                  fontWeight: "700",
                  lineHeight: "1rem",
                }}
              >
                To proceed with this step, kindly import the voting power list
                in either CSV or XLS format. Ensure accurate data
              </p>
              <p
                style={{
                  color: "#344054",
                  fontFamily: "DM Sans",
                  fontSize: "1rem",
                  fontStyle: "normal",
                  fontWeight: "500",
                  lineHeight: "1rem",
                  marginTop: "1%",
                }}
              >
                Import voting power *
              </p>
            </>
          )}
        </div>
        {uploadStatus === null && (
          <div
            className="d-flex justify-content-center align-items-center "
            style={{
              width: "96%",
              height: "15.0625rem",
              alignItems: "center",
              flexShrink: 0,
              borderRadius: "0.5rem",
              border: "1px solid var(--Gray-300, #D0D5DD)",
              background: "var(--Base-White, #FFF)",
              boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
              marginLeft: "2%",
              marginRight: "2%",
            }}
          >
            <div class="spinner-border text-success" role="status">
              <span class="sr-only"></span>
            </div>
            <h4
              style={{
                display: "inline-block",
                fontFamily: "DM Sans",
                fontSize: "1.125rem",
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "1.25rem",
                marginLeft: "15px",
                marginTop: "10px",
              }}
            >
              Checking for data...
            </h4>
          </div>
        )}
        {uploadStatus === "before" && uploadedData === null && (
          <div
            style={{
              width: "96%",
              height: "15.0625rem",
              alignItems: "center",
              flexShrink: 0,
              borderRadius: "0.5rem",
              border: "1px solid var(--Gray-300, #D0D5DD)",
              background: "var(--Base-White, #FFF)",
              boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
              marginLeft: "2%",
              marginRight: "2%",
            }}
          >
            {EditModeTest ? (
              <div
                {...getRootProps()}
                style={{ height: "100%", width: "100%" }}
                className="d-flex justify-content-center align-items-center "
              >
                <div style={{ cursor: "pointer" }}>
                  <div className="d-flex justify-content-center">
                    {UploadCloud}
                  </div>
                  <div>
                    <input {...getInputProps()} />
                    <p
                      style={{
                        color: "#00A86A",
                        display: "inline-block",
                        marginRight: "8px",
                        fontFamily: "Inter",
                        fontSize: "1.125rem",
                        fontStyle: "normal",
                        fontWeight: "600",
                        lineHeight: "1.25rem",
                      }}
                    >
                      Click to upload
                    </p>
                    <p
                      style={{
                        display: "inline-block",
                        fontFamily: "DM Sans",
                        fontSize: "1.125rem",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight: "1.25rem",
                      }}
                    >
                      or drag and drop.
                    </p>
                    <p className="text-center">Type of file : CSV/XLS</p>
                  </div>
                </div>
              </div>
            ) : (
              <div
                style={{
                  backgroundColor: "white",
                  height: "80%",
                  width: "90%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginLeft: "5%",
                }}
              >
                <svg
                  width="184"
                  height="152"
                  viewBox="0 0 184 152"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g fill="none" fill-rule="evenodd">
                    <g transform="translate(24 31.67)">
                      <ellipse
                        fill-opacity=".8"
                        fill="#F5F5F7"
                        cx="67.797"
                        cy="106.89"
                        rx="67.797"
                        ry="12.668"
                      ></ellipse>
                      <path
                        d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
                        fill="#AEB8C2"
                      ></path>
                      <path
                        d="M101.537 86.214L80.63 61.102c-1.001-1.207-2.507-1.867-4.048-1.867H31.724c-1.54 0-3.047.66-4.048 1.867L6.769 86.214v13.792h94.768V86.214z"
                        fill="url(#linearGradient-1)"
                        transform="translate(13.56)"
                      ></path>
                      <path
                        d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
                        fill="#F5F5F7"
                      ></path>
                      <path
                        d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
                        fill="#DCE0E6"
                      ></path>
                    </g>
                    <path
                      d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
                      fill="#DCE0E6"
                    ></path>
                    <g transform="translate(149.65 15.383)" fill="#FFF">
                      <ellipse
                        cx="20.654"
                        cy="3.167"
                        rx="2.849"
                        ry="2.815"
                      ></ellipse>
                      <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z"></path>
                    </g>
                  </g>
                </svg>
                <h4>No Data Available</h4>
              </div>
            )}
            {/* You can add more conditions based on your use case */}
          </div>
        )}
        {uploadStatus === "uploading" && (
          <div
            style={{
              width: "96%",
              height: "15.0625rem",
              alignItems: "center",
              flexShrink: 0,
              borderRadius: "0.5rem",
              border: "1px solid var(--Gray-300, #D0D5DD)",
              background: "var(--Base-White, #FFF)",
              boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
              marginLeft: "2%",
              marginRight: "2%",
            }}
          >
            <div className="p-5">
              <div className="d-flex justify-content-center">
                <Spinner
                  animation="border"
                  role="status"
                  style={{ color: "green" }}
                />
              </div>
              <div className="text-center">
                <p
                  style={{
                    display: "inline-block",
                    color: "#00A86A",
                    marginRight: "8px",
                    fontFamily: "Inter",
                    fontSize: "1.125rem",
                    fontStyle: "normal",
                    fontWeight: "600",
                    lineHeight: "1.25rem",
                  }}
                >
                  Uploading file
                </p>
                <p
                  style={{
                    display: "inline-block",
                    fontFamily: "DM Sans",
                    fontSize: "1.125rem",
                    fontStyle: "normal",
                    fontWeight: "400",
                    lineHeight: "1.25rem",
                  }}
                >
                  please wait...
                </p>
              </div>
            </div>
          </div>
        )}
        {uploadStatus === "success" && uploadedData !== null && (
          <div>
            {/* Display the uploaded data in a table */}
            <div
              style={{
                display: "flex",
                height: "30px",
                width: "100%",
                justifyContent: "space-between",
                marginBottom: "2%",
              }}
            >
              <p
                style={{
                  paddingLeft: "0.5rem",
                  fontFamily: "DM Sans",
                  fontSize: "1.4375rem",
                  fontStyle: "normal",
                  fontWeight: "700",
                  lineHeight: "140%",
                  color: "#2D3748",
                }}
              >
                Voting Power list
              </p>
              {EditModeTest && (
                <button
                  onClick={handleDeleteClick}
                  type="button"
                  className="btn btn-outline-success"
                  style={{
                    border: "none",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {TrashIcon}
                  <span style={{ marginLeft: "5px" }}>Delete List</span>
                </button>
              )}
            </div>

            <table
              className="table-votpower"
              style={{ width: "100%", overflowY: "scroll" }}
            >
              <thead>
                <tr>
                  <th style={{ width: "33%", textAlign: "center" }}>Country</th>
                  <th style={{ width: "33%", textAlign: "center" }}>
                    Regional/Non regional
                  </th>
                  <th style={{ width: "33%", textAlign: "center" }}>
                    Voting Power
                  </th>
                </tr>
              </thead>
              {!isFromBackend ? (
                <tbody>
                  {uploadedData.slice(1).map((row, rowIndex) => (
                    <tr
                      key={rowIndex}
                      style={{
                        backgroundColor:
                          rowIndex % 2 === 0 ? "#F6F6F6" : "white",
                        height: "40px",
                      }}
                    >
                      {row.slice(0, 3).map((cell, cellIndex) => (
                        <td
                          key={cellIndex}
                          style={{ width: "33%", textAlign: "center" }}
                        >
                          {cellIndex === 1 // Check if it's the second column
                            ? cell.toLowerCase() === "true"
                              ? "Regional"
                              : "Non regional"
                            : cell}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              ) : (
                <tbody>
                  {uploadedData.map((country, index) => (
                    <tr
                      key={index}
                      style={{
                        backgroundColor: index % 2 === 0 ? "#F6F6F6" : "white",
                        height: "40px",
                      }}
                    >
                      <td style={{ width: "33%", textAlign: "center" }}>
                        {country.title}
                      </td>
                      <td style={{ width: "33%", textAlign: "center" }}>
                        {country.regional == true ? "Regional" : "Non regional"}
                      </td>
                      <td style={{ width: "33%", textAlign: "center" }}>
                        {country.votingPower?.length > 0
                          ? formatPowerValue(country.votingPower[0].power)
                          : "-"}
                      </td>
                    </tr>
                  ))}
                </tbody>
              )}
            </table>
          </div>
        )}

        {!isFromBackend && (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "98%",
              height: "15%",
              alignItems: "flex-end",
            }}
          >
            {EditModeTest && !disableButton && (
              <button
                type="button"
                class="btn btn-success"
                style={{
                  width: "15%",

                  backgroundColor:
                    uploadStatus === "success" ? "#00A86A" : "#ccc",
                  color: uploadStatus === "success" ? "#fff" : "#000",
                  cursor: uploadStatus === "success" ? "pointer" : "no-drop",
                  border: "none",
                  outline: "none",
                }}
                disabled={uploadStatus !== "success"}
                onClick={onSaveButtonClick}
              >
                Save
              </button>
            )}
          </div>
        )}
      </div>
      {showPopupDeleteVotingPower && (
        <div className="PopupConfirmStart">
          <div className="contentConfirmStart">
            {/* Display based on showEndButton state */}
            <div className="Header">
              <div className="TickIcon">{ShadowedTrash}</div>
              <button
                style={{ border: "none", backgroundColor: "#FFF" }}
                onClick={handlePopupDeleteVotingPowerClose}
              >
                {GreyClose}
              </button>
            </div>
            <h6 style={{ paddingLeft: "0.5rem" }}>Delete Voting power list</h6>
            <p className="informingText" style={{ padding: "0.5rem" }}>
              Would you please confrm deleting the list of voting power
            </p>
            {/* Buttons for confirmation and cancellation */}
            <div className="abstainButtons">
              <button
                className="buttonChange"
                style={{ border: "1px solid #029455", color: "#029455" }}
                onClick={handlePopupDeleteVotingPowerClose}
              >
                Cancel
              </button>

              <button
                className="buttonConfirm"
                style={{ background: "#029455", color: "#FFF" }}
                onClick={removeFile}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default VotingPower;
