import React, { useEffect, useRef, useState } from "react";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useIsAuthenticated,
} from "@azure/msal-react";
import { Banner } from "../components/Banner.jsx";
import CardDisplay from "../components/CardDisplay";
import { fetchToken } from "../services/Token.js";
import { getRoundById, getStartedRound } from "../services/Rounds.js";
import { createWebSocketConnection } from "../services/webSocketService.js";
import {
  checkEligibility,
  checkIfVoteExists,
  getVoterByEmailId,
} from "../services/Voters.js";
import {
  getCurrentElectionID,
  getCurrentElectionYear,
} from "../services/Elections.js";
import "./MainContent.css";
import { greenTick, holdOn, redX } from "../assets/Icons.jsx";
import { AccessDenied } from "./AccessDenied.jsx";
import { SignOutButton } from "../components/SignOutButton.jsx";
import ClassNameGenerator from "@mui/utils/ClassNameGenerator";
import { useConnection } from "../Context/ConnectionContext.js";
import createWebSocketConnectionVotes from "../services/WebSocketVotesService.js";

const MainContent = () => {
  let idTokenClaims = null;
  idTokenClaims = JSON.parse(localStorage.getItem("idTokenClaims"));
  const [clientInfo, setClientInfo] = useState(null);
  const [delayedRender, setDelayedRender] = useState(false);
  const [roundData, setRoundData] = useState([]);
  const [numberValue, setNumberValue] = useState(null);
  const [voterData, setVoterData] = useState(null);
  const [countryStatus, setCountryStatus] = useState(null);
  const [eligibilityCheck, setEligibilityCheck] = useState(true);
  const [accessDenied, setAccessDenied] = useState(false);
  const [roundId, setRoundId] = useState();
  const [currentYear, setCurrentYear] = useState();
  const [votingPower, setVotingPower] = useState();
  const [electionId, setElectionId] = useState(null);
  const [verifVote, setVerifVote] = useState(null);
  const [roundStatus, setRoundStatus] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const prevIsOnline = useRef(navigator.onLine);
  useEffect(() => {
    const timer = setTimeout(() => {
      setDelayedRender(true);
    }, 1500);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  useEffect(() => {
    if (delayedRender) {
      fetchToken(idTokenClaims)
        .then((result) => {
          // Update the state with the resolved value
          setClientInfo(result);
          localStorage.setItem("Token", clientInfo);
        })
        .catch((error) => {
          console.error("Error fetching token:", error);
        });
    }
  }, [delayedRender]);

  useEffect(() => {
    if (delayedRender) {
      getCurrentElectionID(clientInfo).then((result) =>
        setElectionId(result?.id)
      );
    }
  }, [clientInfo]);

  useEffect(() => {
    if (clientInfo !== null && electionId !== null) {
      let socket;

      const initializeWebSocket = () => {
        /** WebSocket initialization */
        socket = createWebSocketConnection();
        socket.onopen = () => {
          // Send the initial message to start receiving data
          const initialMessage =
            JSON.stringify({ protocol: "json", version: 1 }) +
            String.fromCharCode(0x1e);
          socket.send(initialMessage);
        };

        socket.onmessage = (event) => {
          const rawData = event.data;
          const messages = rawData
            .split(String.fromCharCode(0x1e))
            .filter(Boolean);
          console.log("messages", messages);
          if (messages.length > 1) {
            const parsedData = JSON.parse(messages[1]);
            if (parsedData.target === "SendRoundToUser") {
              getCurrentElectionID(clientInfo).then((result) => {
                if (electionId !== result?.id) {
                  setElectionId(result?.id);
                }
              });
              console.log("parsedData", parsedData);
              const roundIdd = parsedData.arguments[0];
              setRoundId(roundIdd);
              getRoundById(clientInfo, roundIdd)
                .then((round) => {
                  setRoundData(round);
                  console.log(round);
                })
                .catch((error) => {
                  console.error("Error fetching round:", error);
                });
            }
          } else if (messages.length === 1) {
            const parsedData = JSON.parse(messages[0]);
            const typevalue = parsedData.type;
            if (parsedData.target === "SendRoundToUser" && typevalue === 1) {
              getCurrentElectionID(clientInfo).then((result) => {
                if (electionId !== result?.id) {
                  setElectionId(result?.id);
                }
              });
              const roundIdd = parsedData.arguments[0];
              setRoundId(roundIdd);
              getRoundById(clientInfo, roundIdd)
                .then((round) => {
                  setRoundData(round);
                  console.log(round);
                })
                .catch((error) => {
                  console.error("Error fetching round:", error);
                });
            }
          }
        };

        socket.onclose = () => {
          console.log("WebSocket disconnected");
        };
      };

      const handleOnline = () => {
        if (!prevIsOnline.current) {
          console.log("You are back online!");
          prevIsOnline.current = true;

          // Reconnect WebSocket when back online
          initializeWebSocket();
        }
      };

      const handleOffline = () => {
        if (prevIsOnline.current) {
          console.log("You are offline, please check your connection.");
          prevIsOnline.current = false;

          // Close the WebSocket when offline
          if (socket) {
            socket.close();
          }
        }
      };

      window.addEventListener("online", handleOnline);
      window.addEventListener("offline", handleOffline);

      /** Get Round Number */
      const roundStarted = getStartedRound(clientInfo, electionId);
      setRoundData(roundStarted);

      // Initialize WebSocket on component mount
      initializeWebSocket();

      /** Get Voter Data */
      getVoterByEmailId(clientInfo, electionId).then((result) => {
        if (result) {
          setVoterData(result?.id);
          setVotingPower(result?.country.votingPower[0]?.power);
          setCountryStatus(result?.country.regional);
          setAccessDenied(false);
        } else {
          setAccessDenied(true);
          setLoading(false);
        }
      });

      // Get current election year
      getCurrentElectionYear(clientInfo, electionId)
        .then((result) => {
          setCurrentYear(result);
        })
        .catch((error) => {
          console.error("Error occurred:", error);
        });

      return () => {
        window.removeEventListener("online", handleOnline);
        window.removeEventListener("offline", handleOffline);
        if (socket) {
          socket.close();
        }
      };
    }
  }, [clientInfo, electionId]);

  useEffect(() => {
    if (roundData instanceof Promise) {
      roundData
        .then((promiseResult) => {
          // Access the 'number' attribute once the promise resolves
          const numberValuee = promiseResult?.number;
          const roundId = promiseResult?.id;
          setRoundId(roundId);
          setNumberValue(numberValuee);
          setRoundStatus(promiseResult?.status);
        })
        .catch((error) => {
          // Handle any errors that occur during the promise resolution
          setEligibilityCheck(false);
          console.error("Error fetching roundData:", error);
        });
    } else {
      setRoundId(roundData?.id);
      setNumberValue(roundData?.number);
      setRoundStatus(roundData?.status);
    }
  }, [roundData]);

  useEffect(() => {
    const fetchData = async () => {
      if (
        delayedRender &&
        roundId !== null &&
        voterData !== null &&
        clientInfo !== null
      ) {
        try {
          setLoading(true);

          const eligibilityResult = await checkEligibility(
            voterData,
            roundId,
            clientInfo
          );
          setEligibilityCheck(eligibilityResult);

          const voteExistsResult = await checkIfVoteExists(
            clientInfo,
            voterData,
            roundId
          );
          setVerifVote(voteExistsResult);
          if (verifVote === "false") {
            setEligibilityCheck(verifVote);
          }
        } catch (error) {
          console.error("Error occurred:", error);
        } finally {
          setLoading(false);
        }
      }
    };
    if (
      !accessDenied &&
      delayedRender &&
      roundId !== null &&
      voterData !== null &&
      clientInfo !== null
    ) {
      fetchData();
    }
  }, [
    delayedRender,
    clientInfo,
    voterData,
    roundId,
    roundStatus,
    roundData,
    // roundUpdateTrigger,
  ]);

  useEffect(() => {
    setLoading(false);
  }, [delayedRender, clientInfo, voterData, eligibilityCheck, verifVote]);

  return (
    <div>
      {!loading ? (
        <div>
          <AuthenticatedTemplate>
            <div
              className="page-container"
              style={{
                background:
                  countryStatus === true
                    ? "#FFFCAC"
                    : countryStatus === false
                    ? "rgba(103, 194, 101, 0.68)"
                    : "white",
              }}
            >
              {accessDenied ? (
                <div
                  className="container-fluid"
                  style={{ backgroundColor: "#FAFBFC" }}
                >
                  <div className="row">
                    <div
                      className="row"
                      style={{
                        height: "100vh",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        textAlign: "center",
                      }}
                    >
                      {holdOn}
                      <h6
                        style={{
                          fontFamily: "DM Sans",
                          fontSize: "24px",
                          fontWeight: "700",
                          lineHeight: "31px",
                          letterSpacing: "0em",
                          marginBottom: "0.5rem",
                          marginTop: "1rem",
                        }}
                      >
                        Hold on!/ Patientez!
                      </h6>
                      <p
                        style={{
                          fontFamily: "DM Sans",
                          fontSize: "18px",
                          fontWeight: "400",
                          lineHeight: "28.64px",
                          textAlign: "center",
                          color: " rgba(45, 55, 72, 1)",
                          marginBottom: "1rem",
                        }}
                      >
                        {" "}
                        Patientez! Le vote n'a pas commencé
                        <br />
                        Hold on! Vote has not started yet
                      </p>
                      <SignOutButton />
                    </div>
                  </div>
                </div>
              ) : !eligibilityCheck ||
                roundData?.status === "Closed" ||
                roundData?.status === "Created" ? (
                <div className="unavailableMessage">
                  <div className="contentUnavailableMessage">
                    <div className="XIcon">{redX}</div>
                    <h6> Hold on!/ Patientez!</h6>
                    <p
                      style={{
                        fontFamily: "DM Sans",
                        fontSize: "18px",
                        fontWeight: "400",
                        lineHeight: "28.64px",
                        textAlign: "center",
                        color: " rgba(45, 55, 72, 1)",
                        marginBottom: "1rem",
                      }}
                    >
                      {" "}
                      Patientez! Le vote n'a pas commencé
                      <br />
                      Hold on! Vote has not started yet
                    </p>
                    <div
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <SignOutButton />
                    </div>
                  </div>
                </div>
              ) : verifVote ? (
                // && roundData?.status === "Closed"
                <div>
                  <div className="successVote">
                    <div className="content">
                      <div
                        style={{
                          width: "0.875rem",
                          height: "13rem",
                          flexShrink: "0",
                        }}
                      >
                        {countryStatus === true ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="208"
                            viewBox="0 0 14 208"
                            fill="none"
                          >
                            <path d="M0 0H14V208H0V0Z" fill="#EBB64F" />
                          </svg>
                        ) : (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="208"
                            viewBox="0 0 14 208"
                            fill="none"
                          >
                            <path d="M0 0H14V208H0V0Z" fill="#029455" />
                          </svg>
                        )}
                      </div>
                      <div>
                        <div
                          style={{ marginTop: "1.5rem", marginLeft: "1.5rem" }}
                        >
                          {greenTick}
                        </div>
                        <h6 style={{ marginLeft: "1.5rem" }}>
                          Vote soumis avec succès /Vote successfully submitted{" "}
                        </h6>
                        <p style={{ marginLeft: "1.5rem" }}>
                          Vous avez soumis votre vote avec succès / You have
                          successfully submitted your vote.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div>
                  <CardDisplay
                    clientInfo={clientInfo}
                    votingPower={votingPower}
                    roundId={roundId}
                    countryStatus={countryStatus}
                    voterData={voterData}
                    currentYear={currentYear}
                    roundData={roundData}
                    electionId={electionId}
                    loading={loading}
                  />
                </div>
              )}
            </div>
          </AuthenticatedTemplate>
        </div>
      ) : (
        <>
          <div className="container-fluid text-center">
            <div
              className="w-100 d-flex flex-column align-items-center justify-content-center"
              style={{ overflow: "hidden", height: "100vh" }}
            >
              <div className="w-100 d-flex flex-column justify-content-center align-items-center">
                <div
                  className="spinner-border text-success"
                  role="status"
                  style={{ width: "70px", height: "70px" }}
                ></div>
                <p
                  style={{
                    fontFamily: "DM Sans",
                    fontSize: "1.7rem",
                    fontStyle: "normal",
                    fontWeight: "700",
                    lineHeight: "normal",
                    marginTop: "10px",
                  }}
                >
                  Veuillez patienter / Please wait
                </p>
                <SignOutButton />
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default MainContent;
